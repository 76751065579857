export const isRequired = (value: string | undefined | null) => {
  return value === undefined || value === null || value === '' ? 'This field is required' : null
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const chainIsRequired = (func: (v: any, vals: { [key: string]: any }) => string | null) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (v: string | undefined | null, vals: { [key: string]: any }) =>
    v === undefined || v === null || v === '' ? 'This field is required' : func(v, vals)
}
