.root {
  min-height: calc(100vh - 7.5rem);
  min-height: calc(100dvh - 7.5rem);
  isolation: isolate;
}

.formColumn {
  border-left: 2pt solid var(--mantine-color-primary-6);
}

.formColumnFooter {
  width: 100%;
  background-color: var(--mantine-color-primary-6);
  text-align: center;
  color: var(--mantine-color-dark-7);
  padding: 6px 0;
  font-family: var(--mantine-font-family);
  font-weight: 700;
}

.applyColumn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
