import { ActionIcon, Box, Button, Drawer, DrawerProps, Flex, MantineColor, ScrollArea, Text, getThemeColor, useMantineTheme } from '@mantine/core'
import React, { CSSProperties, useState } from 'react'
import { IconArrowDropDown, IconMenu } from '../../../assets/icons'

export interface MenuItemProps {
  textColor?: MantineColor
  descriptionColor?: MantineColor
  borderColor?: MantineColor
  borderWidth?: CSSProperties['borderWidth']
  borderPosition?: 'top' | 'bottom' | 'top and bottom'
  onHoverBackgroundColor?: MantineColor
  title: string
  description: string
  icon?: React.ReactNode
  align?: 'left' | 'right'
  onClick?: () => void
}
export interface MenuProps extends Omit<DrawerProps, 'onClose'> {
  menuButtonTitle?: string | React.ReactNode
  onClose?: () => void
  setOpened: (opened: boolean) => void
}

export const Menu = ({ menuButtonTitle, children, onClose, setOpened, classNames, ...props }: MenuProps) => {
  return (
    <>
      <Drawer
        padding='xl'
        onClose={() => {
          setOpened(false)
          onClose && onClose()
        }}
        styles={{
          header: {
            backgroundColor: '--mantine-color-dark-7',
          },
          content: {
            overflow: 'auto',
            backgroundColor: '--mantine-color-dark-7',
          },
          title: {
            width: '100%',
          },
          overlay: {
            backdropFilter: 'blur(5px)',
          },
        }}
        overlayProps={{
          color: '#00000019',
        }}
        scrollAreaComponent={ScrollArea.Autosize}
        {...props}
        closeButtonProps={{
          size: 'lg',
          ...props.closeButtonProps,
        }}
      >
        {children}
      </Drawer>
      <Button
        onClick={() => {
          setOpened(true)
        }}
        color='primary.6'
        variant='subtle'
        py={0}
        px='xs'
        visibleFrom='md'
        leftSection={<Box component={IconMenu} h='2.3em' />}
      >
        {menuButtonTitle}
      </Button>
      <ActionIcon
        onClick={() => {
          setOpened(true)
        }}
        color='primary.6'
        variant='subtle'
        size='lg'
        p={4}
        hiddenFrom='md'
      >
        {<Box component={IconMenu} h='2.3em' />}
      </ActionIcon>
    </>
  )
}

export const MenuItem: React.FC<React.PropsWithChildren<MenuItemProps>> = ({
  children,
  title,
  description,
  textColor,
  descriptionColor,
  borderColor = 'white',
  borderWidth = '2pt',
  borderPosition,
  icon,
  align,
  onClick,
}) => {
  const [showSubItems, setShowSubItems] = useState(false)
  const borderStyles: CSSProperties = {}
  const theme = useMantineTheme()
  const themeBorderColor = getThemeColor(borderColor, theme)
  if (borderPosition === 'top' || borderPosition === 'top and bottom') {
    borderStyles.borderTop = `${borderWidth} solid ${themeBorderColor}`
  }

  if (borderPosition === 'bottom' || borderPosition === 'top and bottom') {
    borderStyles.borderBottom = `${borderWidth} solid ${themeBorderColor}`
  }

  return (
    <Flex direction='column' c={textColor}>
      <Flex
        justify='space-between'
        align='center'
        p='md'
        fz='xl'
        style={{
          cursor: 'pointer',
          userSelect: 'none',
          ...borderStyles,
        }}
        key={title}
        onClick={() => {
          children !== undefined ? setShowSubItems(!showSubItems) : null
          onClick ? onClick() : null
        }}
      >
        <Flex w='100%' c={textColor} justify={align === 'right' ? 'flex-end' : 'flex-start'} ta={align === 'right' ? 'right' : 'left'}>
          {icon && <Box w='10%'>{icon}</Box>}
          <Flex direction='column' c={textColor}>
            <Text fz='lg' lh='1.3' fw={600}>
              {title}
            </Text>
            <Text lh='1.3' fz='sm' c={descriptionColor ?? 'dark.0'}>
              {description}
            </Text>
          </Flex>
        </Flex>
        {children !== undefined && (
          <Box>
            <Box
              component={IconArrowDropDown}
              style={{
                transition: 'transform 0.5s ease-in-out 0s',
                transform: showSubItems ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </Box>
        )}
      </Flex>

      {showSubItems && children}
    </Flex>
  )
}
