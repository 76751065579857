import { Box, BoxProps } from '@mantine/core'
import React from 'react'
import classes from './Container.module.css'

export const Container: React.FC<React.PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
  return (
    <Box {...props} className={`${classes.root} ${props.className ?? ''}`}>
      {children}
    </Box>
  )
}

export default Container
