import { Box, Button, Container, Flex, Text, TextInput } from '@mantine/core'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from '@mantine/form'
import API from '../../backend/api'
import Navbar from '../../components/layout/Navbar'
import Header from '../../components/common/Header'
import { PageEncryptedReminder } from '../../components/common/PageEncryptedReminder'
import Footer from '../../components/layout/Footer'
import classes from './ForgotPassword.module.css'

const ForgotPassword = () => {
  const [success, setSuccess] = useState(false)
  const [resetError, setResetError] = useState<string | undefined>()

  const form = useForm({
    initialValues: { email: '' },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  })

  const sendResetPasswordLink = async () => {
    if (form.isValid()) {
      setResetError(undefined)
      try {
        await API.sendPasswordResetLink(form.values)
        setSuccess(true)
      } catch (err) {
        setResetError((err as string).toString())
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Card Noir - Forgot Password</title>
      </Helmet>
      <Navbar />
      <Box className={classes.root}>
        <Header>Forgot your password?</Header>
        <Container>
          <Box maw={392} mx='auto'>
            <Box className={classes.formColumn}>
              <TextInput
                mb={32}
                type='email'
                label='Email address'
                id='email'
                description='The verified email address linked to your profile. We will email instructions for resetting your password.'
                {...form.getInputProps('email')}
                autoFocus={true}
              />
              {resetError && <Text c='error' size='xs' mt='0.4rem' mb={16}>{resetError}</Text>}
              {success && (
                <Text mb={16} fz={14}>
                  Please check your email inbox for more information.
                </Text>
              )}
              <Flex justify='end'>
                <Button disabled={!form.isValid()} type='submit' onClick={sendResetPasswordLink} tt='uppercase' color='success' px={42}>
                  SEND
                </Button>
              </Flex>
            </Box>
          </Box>
        </Container>
        <PageEncryptedReminder justify='center' />
        <Footer />
      </Box>
    </>
  )
}

export default ForgotPassword
