.timelineItem {
  cursor: pointer;
  margin-left: 1rem;
  &:nth-of-type(even) {
    background: var(--mantine-color-dark-7);
  }
}

.transactionItem {
  font-size: var(--mantine-font-size-sm);
  font-weight: bold;
  color: var(--mantine-color-primary-6);
  position: relative;
  margin-top: 0.4rem;

  &::before {
    content: '';
    width: 22px;
    display: block;
    height: 22px;
    background-color: var(--mantine-color-primary-6);
    border: 2pt solid var(--mantine-color-primary-6);
    border-radius: 50%;
    position: absolute;
    left: -13px;
  }
}
