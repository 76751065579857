import { ActionIcon, Button, Chip, Input, InputWrapper, createTheme } from '@mantine/core'
import classes from './Theme.module.css'

export default createTheme({
  primaryShade: 6, // default primary shade for dark is 8.
  primaryColor: 'primary',
  fontFamily: '"Raleway", sans-serif',
  cursorType: 'pointer',
  white: '#f9f6f6',
  black: '#080808',
  colors: {
    // TODO: generate colors
    primary: [
      '#feedd2',
      '#fee3b9',
      '#fdd9a0',
      '#fdd087', //#fed481 primary.3 -> primaryHighlight
      '#fcc66e', //
      '#fcbc55',
      '#fbb03b', // default
      '#f49d10',
      '#c17d10',
      '#8e5e10', // #91641c primary.9 -> primaryMuted
    ],

    success: [
      '#cce7c0',
      '#bde0ae',
      '#aed99b',
      '#9fd289', // success.2 -> successHighlight
      '#90cb76',
      '#81c464',
      '#72bd51', // default
      '#5a9f3c',
      '#457a2e',
      '#305520',
    ],

    error: [
      // '#fad1d3',
      '#fbbbbd',
      '#faa3a7',
      '#f99093',
      '#fa7a7e',
      '#ff5257', // highlights?
      '#ff242b', // #F03E3E #E03131 red.8 ?
      '#da0b12',
      '#c20a10',
      '#9b080d',
      '#7f0a0e',
    ],

    // gray with reddish tint rather than blue
    nebulous: [
      '#f9f6f6', //textPrimary
      '#f4f0f0',
      '#f0eaea',
      '#e7dfdf',
      '#d9cece',
      '#c5baba',
      '#aba1a0', // default
      '#8d8281',
      '#6d6564',
      '#444140',
    ],

    // muted: dark.0
    // background: dark.7 // dark
    // dark: dark.9
    // backgroundMuted: dark.4
    // textPrimary: nebulous.0
    // textSecondary: dark.0
    // virtualCardHighlight: cyan.3
    // physicalCardHighlight: primary.3
    // outline: orange.8

    // success: generateShades(theme, '#72bb53'),
    // error: generateShades(theme, '#ed1c24'),
    // muted: generateShades(theme, '#c0c0c0'), #c9c9c9 ##dacece
    // background: generateShades(theme, '#323232'), #2E2E2E
    // dark: generateShades(theme, '#1a1a1a'),
    // backgroundMuted: generateShades(theme, '#444444'),
    // textPrimary: generateShades(theme, '#f7f6f6'),
    // textSecondary: generateShades(theme, '#c0c0c0'),
    // buttonDisabled: generateShades(theme, '#666666'),
    // errorHighlight: generateShades(theme, '#ef545a'),
    // successHighlight: generateShades(theme, '#acdd92'),
    // primaryHighlight: generateShades(theme, '#fed683'),
    // physicalCardHighlight: generateShades(theme, '#fed683'),
    // virtualCardHighlight: generateShades(theme, '#90e4fc'), ##99E9F2 // cyan.3
    // white: generateShades(theme, '#ffffff'),
    // primaryMuted: generateShades(theme, '#91641c'),
    // label: '#f7f6f6',
    // input: '#fbb03b',
    // dropdownSelectedBackground: '#444444',
    // dropdownSelectedText: '#fbb03b',
    // unset: 'unset',
    // transparent: 'transparent',
    // dark: '#1a1a1a', // use darker background index?
    // darker: '#151515',
    // darkest: '#080808',
    // outline: '#e25c1e',
  },
  spacing: {
    xxs: 'calc(0.5rem * var(--mantine-scale))',
    xs: 'calc(0.625rem * var(--mantine-scale))',
    sm: 'calc(0.75rem * var(--mantine-scale))',
    md: 'calc(1rem * var(--mantine-scale))',
    lg: 'calc(1.25rem * var(--mantine-scale))',
    xl: 'calc(1.5rem * var(--mantine-scale))',
    xxl: 'calc(2rem * var(--mantine-scale))',
    xxxl: 'calc(2.5rem * var(--mantine-scale))',
  },

  breakpoints: {
    xs: '25.875em',
  },
  headings: {
    fontFamily: '"Raleway", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
    fontWeight: '700',
    sizes: {
      h1: { fontSize: '2rem', lineHeight: '1.3', fontWeight: undefined },
      h2: { fontSize: '1.5rem', lineHeight: '1.35', fontWeight: undefined },
      h3: { fontSize: '1.125rem', lineHeight: '1.4', fontWeight: undefined },
    },
  },
  components: {
    InputWrapper: InputWrapper.extend({
      defaultProps: {
        inputWrapperOrder: ['label', 'input', 'description', 'error'],
      },
      classNames: { error: classes.inputWrapperError },
    }),
    Input: Input.extend({
      classNames: { input: classes.input },
    }),
    Button: Button.extend({
      classNames: { root: classes.button, inner: classes.buttonInner, section: classes.buttonSection, label: classes.buttonLabel },
    }),
    ActionIcon: ActionIcon.extend({
      classNames: { root: classes.actionIcon },
    }),
    Chip: Chip.extend({
      styles: { input: { position: 'absolute' } },
    }),
  },
  // components: {
  //   Select: {
  //     defaultProps: {
  //       dropdownPosition: 'bottom',
  //     },
  //   },
  // },
  shadows: {
    cardnoir: '0 0 3px 0 rgba(255, 255, 255, 0.2), 0 0 15px 3px rgba(255, 255, 255, 0.15), 0 0 20px -3px rgba(255, 255, 255, 0.1)',
    'cardnoir-lg': '0 0 6px 0 rgba(255, 255, 255, 0.2), 0 0 22px 5px rgba(255, 255, 255, 0.15), 0 0 25px -7px rgba(255, 255, 255, 0.1)',
    xs: '0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(255, 255, 255, 0.05), 0 calc(0.0625rem * var(--mantine-scale)) calc(0.125rem * var(--mantine-scale)) rgba(255, 255, 255, 0.1)',
    sm: '0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05) 0 calc(0.625rem * var(--mantine-scale)) calc(0.9375rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale)), rgba(255, 255, 255, 0.04) 0 calc(0.4375rem * var(--mantine-scale)) calc(0.4375rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale))',
    md: '0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05) 0 calc(1.25rem * var(--mantine-scale)) calc(1.5625rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale)), rgba(255, 255, 255, 0.04) 0 calc(0.625rem * var(--mantine-scale)) calc(0.625rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale))',
    lg: '0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05) 0 calc(1.75rem * var(--mantine-scale)) calc(1.4375rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale)), rgba(255, 255, 255, 0.04) 0 calc(0.75rem * var(--mantine-scale)) calc(0.75rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale))',
    xl: '0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05) 0 calc(2.25rem * var(--mantine-scale)) calc(1.75rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale)), rgba(255, 255, 255, 0.04) 0 calc(1.0625rem * var(--mantine-scale)) calc(1.0625rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale))',
  },
  // other: {
  //   shadow: '#f7f6f64c', // configure as a shadow
  //   // move these to font sizes if needed.
  //   slightlyLarger: '20px', // xl
  //   normal: '16px', // md
  //   small: '14px', //sm
  //   extraSmall: '12px', //xs
  // },
})
