.container {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
}

.nonClickableIcon {
  padding: 5pt;
  height: 2.75rem;
  aspect-ratio: 1 / 1;
  border: 2pt solid var(--mantine-color-primary-6);
  border-radius: 0.375rem;
  svg {
    fill: var(--mantine-color-primary-6);
  }
}

.icon {
  padding: 5pt;
  height: 2.75rem;
  aspect-ratio: 1 / 1;
  border: 2pt solid var(--mantine-color-primary-6);
  border-radius: 0.375rem;
  color: var(--mantine-color-primary-6)
}
