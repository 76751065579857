.root {
  background-color: var(--mantine-color-dark-7);
  padding: 0.75rem;
}

.container {
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;

  @media (min-width: 62em) {
    flex-direction: row;
    gap: 2.5rem;
  }
}

.linksWrapper {
  width: 100%;
  min-width: 205px;

  @media (min-width: 62em) {
    width: auto;
  }

  & > a {
    color: var(--mantine-color-dark-0);
    font-weight: 500;
    line-height: 1.3;
    padding: 0.25rem 1rem;
    display: block;
    text-decoration: none;
    text-align: center;
    cursor: pointer;

    &:hover {
      color: var(--mantine-primary-color-6);
    }

    @media (min-width: 62em) {
      text-align: left;
      padding: 0 1rem;
    }
  }
}

.legalWrapper {
  font-size: var(--mantine-font-size-xs);
  color: #929292;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  @media (min-width: 62em) {
    margin-top: unset;
    margin-bottom: unset;
  }
}

.iconWrapper {
  display: flex;
  gap: 1rem;
}

.masterCardIcon {
  height: 43px;
  @media (min-width: 62em) {
    height: 55px;
  }
}

.visaIcon {
  height: 40px;
  @media (min-width: 62em) {
    height: 50px;
  }
}
