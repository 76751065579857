import { CSSVariablesResolver, MantineProvider, VariantColorsResolver, defaultVariantColorsResolver, parseThemeColor, rem, rgba } from '@mantine/core'
import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import '@mantine/carousel/styles.css'

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import timezone from 'dayjs/plugin/timezone'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import './index.css'
import mantineTheme from './theme/mantineTheme'

dayjs.extend(advancedFormat)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrBefore)

// Mount the app
const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!) // createRoot(container!) if you use TypeScript

const resolver: CSSVariablesResolver = (theme) => ({
  variables: {
    '--mantine-color-primary-text': theme.colors.primary[6],
    '--mantine-color-body': theme.colors.dark[7],
    '--mantine-color-text': theme.white,
    '--mantine-color-dimmed': theme.colors.dark[0],
    '--mantine-color-error': theme.colors.error[4],
  },
  light: {
    '--mantine-color-primary-text': theme.colors.primary[6],
    '--mantine-color-body': theme.colors.dark[7],
    '--mantine-color-text': theme.white,
    '--mantine-color-dimmed': theme.colors.dark[0],
    '--mantine-color-error': theme.colors.error[4],
  },
  dark: {
    '--mantine-color-primary-text': theme.colors.primary[6],
    '--mantine-color-body': theme.colors.dark[7],
    '--mantine-color-text': theme.white,
    '--mantine-color-dimmed': theme.colors.dark[0],
    '--mantine-color-error': theme.colors.error[4],
  },
})

const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input)
  const parsedColor = parseThemeColor({
    color: input.color || input.theme.primaryColor,
    theme: input.theme,
  })

  if (input.variant === 'cardnoir') {
    return {
      background: 'transparent',
      hover: rgba(parsedColor.value, 0.15),
      border: `${rem(2)} solid ${parsedColor.value}`,
      color: parsedColor.value,
    }
  }

  if (input.variant === 'cardnoir-white') {
    return {
      background: 'transparent',
      hover: rgba(parsedColor.value, 0.15),
      border: `${rem(2)} solid ${parsedColor.value}`,
      color: 'var(--mantine-color-nebulous-0)',
    }
  }
  if (input.variant === 'vertical') {
    return {
      background: 'transparent',
      hover: rgba(parsedColor.value, 0.15),
      border: `${rem(2)} solid ${parsedColor.value}`,
      color: 'var(--mantine-color-white-0)',
    }
  }

  return defaultResolvedColors
}

root.render(
  <React.StrictMode>
    <MantineProvider theme={{ ...mantineTheme, variantColorResolver }} defaultColorScheme='dark' cssVariablesResolver={resolver}>
      <App />
    </MantineProvider>
  </React.StrictMode>
)
