import { ActionIcon, Box, Button } from '@mantine/core'
import { useContext } from 'react'
import { IconPlusOrderCard } from '../../../assets/icons'
import IconProfileInformation from '../../../assets/icons/Icon_Profile_Information_SVG.svg'
import ChatButton from '../../../components/ChatButton/ChatButton'
import AppHeader from '../../../components/layout/AppHeader'
import AppWrapper from '../../../components/layout/AppWrapper'
import Container from '../../../components/layout/Container'
import { AuthContext } from '../../../contexts'
import AccountOverview from './AccountOverview'

const Dashboard = () => {
  const { user, lastLoggedIn } = useContext(AuthContext)

  return (
    <AppWrapper
      title='Card Noir - Dashboard'
      appHeader={
        <AppHeader
          title='Accounts | Card overview'
          icon={<IconProfileInformation style={{ color: 'var(--mantine-color-primary-6)', height: '1.6em', width: '1.6em' }} />}
          buttons={[
            <Button
              key='order-card'
              size='md'
              variant='cardnoir'
              leftSection={<IconPlusOrderCard height='1.5em' />}
              color='success.4'
              style={{ justifySelf: 'start' }}
              visibleFrom='md'
            >
              <Box>+ Add More Cards</Box>
              <Box>Order physical or add virtual cards</Box>
            </Button>,
            <ActionIcon key='order-card-2' color='success' variant='outline' size='md' hiddenFrom='md'>
              <IconPlusOrderCard height='1.5em' />
            </ActionIcon>,
          ]}
          description={`Last log in - ${lastLoggedIn}` || ''}
        />
      }
    >
      <Container px='0' py={{ base: '2rem', md: '2rem' }} display='grid'>
        {user.accounts.map((v, i) => (
          <AccountOverview key={v.id} account={v} isFirst={i === 0} />
        ))}
        <ChatButton />
      </Container>
    </AppWrapper>
  )
}

export default Dashboard
