import React, { createContext } from 'react'
import { Session, User } from './utils/interfaces'

interface AuthContextInterface {
  token: string
  setToken: (token: string) => void
  clearToken: () => void
  user: User
  setUser: (user: User) => void
  lastLoggedIn: string
}
export const AuthContext = createContext<AuthContextInterface>({} as AuthContextInterface)

export interface SessionContextInterface {
  session: Session
  dispatch: React.Dispatch<{ [key: string]: never }>
  clearSession: () => void
}
export const SessionContext = createContext<SessionContextInterface>({} as SessionContextInterface)
