import React, { useReducer } from 'react'
import mockedData from '../backend/mockedData'
import { Session } from './interfaces'
import sessionReducer from './sessionReducer'

const initSession = {
  version: 1,
  ...mockedData,
}

/** Hook that manages user session, and exposes current
 * session state, as well as function(s) to manage this
 * state. Typically used by top-level components that wish to
 * manage an session context - i.e. usually at the top-level
 * of the app. The return value is an object suitable to use as the
 * value for a `SessionContext.Provider`.
 */

export const useSessionManager = () => {

  const localStorageSession = localStorage.getItem('card-noir-session')
  let cardNoirSession = localStorageSession !== null ? JSON.parse(localStorageSession) : initSession
  localStorage.setItem('card-noir-session', JSON.stringify(cardNoirSession))

  if (cardNoirSession.version != initSession.version) {
    /* reset session if a new version is needed - avoids unstable state. 
    shouldn't cause any issue if we are pulling info from API */
    cardNoirSession = initSession
    localStorage.setItem('card-noir-session', JSON.stringify(cardNoirSession))
  }

  const [session, dispatch] = useReducer(sessionReducer, cardNoirSession)

  const clearSession = () => {
    localStorage.removeItem('card-noir-session')
  }

  return [session, dispatch, clearSession] as [Session, React.Dispatch<{ [key: string]: never }>, () => void]
}
