import { Box } from '@mantine/core'
import AppFooter from './AppFooter'
import Container from './Container'
import classes from './Footer.module.css'
import { IconSocialFacebook, IconSocialInstagram, IconSocialTwitter } from '../../assets/icons'

export const Footer = () => {
  return (
    <Box pos='relative'>
      <Box p='lg' bg='black'>
        <Container className={classes.topFooterContainer}>
          <div className={classes.linksWrapper}>
            <a>Home</a>
            <a>Card &amp; Pricing</a>
            <a>About us</a>
          </div>
          <div className={classes.linksWrapper}>
            <a>Customer support</a>
            <a>Contact us</a>
            <a>FAQ</a>
          </div>
          {/* <div className={classes.inputWrapper}>
            <Box mb={4}>Join our newsletter for exclusive offers</Box>
            <div>
              <TextInput description='Be first to hear about all our news &amp; updates. Unsubscribe any time.' />
              <div>
                <Button color='success' py='0.4rem' h='auto' ta='center' lh='1.2'>
                  JOIN
                  <br />
                  NOW
                </Button>
              </div>
            </div>
          </div> */}
          <div className={classes.socialIconsWrapper}>
            <IconSocialTwitter />
            <IconSocialFacebook />
            <IconSocialInstagram />
          </div>
        </Container>
      </Box>
      <AppFooter className={classes.appFooter} />
    </Box>
  )
}

export default Footer
