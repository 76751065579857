import { useContext } from 'react'
import { Badge, Box, Divider, Flex, Paper, Text, Title } from '@mantine/core'
import { useLocation, useParams } from 'wouter'

import { IconArrowBack, IconViewInfoEye } from '../../../assets/icons'
import { CSSGrid } from '../../../components/CSSGrid'
import AppHeader from '../../../components/layout/AppHeader'
import AppWrapper from '../../../components/layout/AppWrapper'
import Container from '../../../components/layout/Container'
import Card from '../../../components/Card'
import { AuthContext } from '../../../contexts'
import CardOptions from './CardOptions/CardOptions'
import CardsOverview from './CardsOverview/CardsOverview'
import TimelineWrapper from './TimelineWrapper/TimelineWrapper'
import { User } from '../../../utils/interfaces'
import classes from './index.module.css'

const Welcome = ({ user }: { user: User }) => {
  const [, navigate] = useLocation()
  return (
    <Container py={{ base: '1.5rem', md: '3rem' }}>
      <Title mb='lg' order={2} ta='center'>Your Accounts</Title>
      <Text mb='lg' ta='center'>Select your account to view and manage cards, transactions and statements</Text>
      <Divider h='3rem' color='var(--mantine-color-primary-9' pt='2rem' />
      <Flex className={classes.flexContainer}>
        {user.accounts.map((account) => (
          <Flex
            className={classes.account}
            key={account.id}
            style={{
              userSelect: 'none',
              cursor: 'pointer',
              ':hover': {
                'background': 'white',
              },
              border: '1px solid var(--mantine-color-primary-6)',
            }}
            onClick={() => {
              navigate(`~/member/statement-centre/${account.id}`, { replace: true })
            }}
          >
            <Box px='4rem' py={{ base: 'lg', md: 'md' }}>
              <Flex justify='center' w='100%' align='center' gap='lg' mb='md'>
                <Title fz='lg'>{account.name}</Title>
                <IconViewInfoEye height='1.5em' />
              </Flex>
              <Badge mb='xxl'>{account.type} account</Badge>
              <Flex justify='center' w='100%' align='center' direction='row'>
                {account.cards.map((card) => <Card key={card.id} w={{ base: 80, md: 80 }} card={card} />)}
              </Flex>
            </Box>
            <Box px='6rem'>
              <Text fz='md' mb={{ base: 'xs', md: 'lg' }}>
                {account.cards.length}&nbsp;{account.cards.length === 1 ? 'card' : 'cards'}
              </Text>
              <Flex justify='flex-start' w='100%' direction={{ base: 'column', md: 'row' }} gap={{ base: 0, md: 'lg' }}>
                <Box lh={2} className={classes.accountLabel}>Balance</Box>
                <Box lh={2} className={classes.accountInfo}>{account.balance}&nbsp;{account.currency}</Box>
              </Flex>
              <Flex justify='flex-start' w='100%' direction={{ base: 'column', md: 'row' }} gap={{ base: 0, md: 'lg' }} mb='lg'>
                <Box lh={2} className={classes.accountLabel}>IBAN</Box>
                <Box lh={2} className={classes.accountInfo}>{account.bank_details.iban}</Box>
              </Flex>
            </Box>
          </Flex>
        ))}
      </Flex>
    </Container>
  )
}

const StatementCentre = () => {
  const { id } = useParams()
  const [, navigate] = useLocation()

  const { user } = useContext(AuthContext)

  const account = user.accounts.find((acc) => acc.id === id)
  const backLocation = account ? '~/member/statement-centre' : '~/member'

  return (
    <AppWrapper
      title='Card Noir - Statement Centre'
      appHeader={
        <AppHeader
          title={account ? account.name : 'Statement Centre'}
          description={account ? 'Card overview, transact & statements' : 'Manage, navigate and export statements'}
          icon={<IconArrowBack height='1.5em' />}
          iconOnClick={() => {
            navigate(backLocation)
          }}
        />
      }
    >
      {!account ? (
        <Welcome user={user} />
      ) : (
        <Container py={{ base: '1.5rem', md: '3rem' }}>
          <CSSGrid
            templateColumns={{ base: '1fr', md: 'auto 1fr' }}
            alignItems='start'
            gap={{ base: '1rem', md: '3rem' }}
            templateAreas={{ base: 'cardOptions report', md: '"cardOptions report"' }}
          >
            <Paper radius='md' w={{ base: '100%', md: 'auto' }} mih={275} className={classes.mobileNoShadow}>
              <CardsOverview account={account} user={user} />
              {account && <CardOptions account={account} />}
            </Paper>
            <Box>{account && <TimelineWrapper account={account} user={user} />}</Box>
          </CSSGrid>
        </Container>
      )}
    </AppWrapper>
  )
}

export default StatementCentre
