import { ModalProps } from '@mantine/core'
import AddBeneficiary from './AddBeneficiary'
import classes from './index.module.css'
import SendFunds from './SendFunds'

const modals = {
  addBeneficiary: AddBeneficiary,
  sendFunds: SendFunds,
}

export default modals

export const modalProps: Partial<Omit<ModalProps, 'opened'>> & {
  modalId?: string
} = {
  classNames: classes,
  centered: true,
  padding: '0', // works with margin/padding in the ModalWrapper to have the scrollbar appear nicely when needed
  // radius: 'lg',
  shadow: 'cardnoir',
  overlayProps: { blur: 4, backgroundOpacity: 0.3 },
}
