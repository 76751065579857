.topFooterContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;

  @media (min-width: 62em) {
    flex-direction: row;
    gap: 1.5rem;
  }

  @media (min-width: 72em) {
    gap: 2.5rem;
  }
}

.linksWrapper {
  width: '100%';

  @media (min-width: 62em) {
    width: auto;
  }
}

.linksWrapper > a {
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.3;
  padding: 0.25rem 1rem;
  color: var(--mantine-color-nebulous-0);
  display: block;
  text-decoration: none;
  text-align: center;

  &:hover {
    color: var(--mantine-color-primary-6);
  }

  @media (min-width: 62em) {
    text-align: left;
    padding: 0 1rem;
  }
}

.inputWrapper {
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: start;

  @media (min-width: 62em) {
    margin-top: 0;
    margin-bottom: 0;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  & > .appFooter {
    background-color: var(--mantine-color-dark-7);
  }
}

.socialIconsWrapper {
  margin-top: 1rem;

  & svg {
    height: 50px;
    width: 50px;
    margin-left: 8px;
    fill: #919191;
  }
}
