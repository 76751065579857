import ChatIcon from '../../assets/icons/Icon_Contact_Us.svg'
import { Button, Stack } from '@mantine/core'
import classes from './ChatButton.module.css'

const ChatButton = ({ className }: { className?: string }) => {
  return (
    <div style={{ position: 'relative' }} className={className}>
      <button className={classes.iconButtonWrapper}>
        <div className={classes.chatIconWrapper}>
          <ChatIcon className={classes.desktopChatIcon} />
        </div>
        <div className={classes.desktopContactSupportWrapper}>
          Contact
          <br />
          Support
        </div>
      </button>
      <Button variant='outline' leftSection={<ChatIcon height='1.5em' />} color='primary' size='lg' w='100%' hiddenFrom='md'>
        <Stack gap={0} align='center' c='dark.0' p='2rem'>
          <div className={classes.mobileBtnHeader}>Help, Contact Us &amp; Feedback</div>
          <div className={classes.mobileBtnDescription}>Reach out for help, and getting in touch</div>
        </Stack>
      </Button>
    </div>
  )
}

export default ChatButton
