import { Box, Burger, Button, Center, Drawer, Flex, FlexProps } from '@mantine/core'
import { useContext, useState } from 'react'
import { Link, useLocation } from 'wouter'
import NavbarBackground from '../../assets/top_decco.svg'
import { AuthContext, SessionContext } from '../../contexts'
import Container from './Container'
import { CardNoirLogo, IconLogin, IconLogout } from '../../assets/icons'

const Links: React.FC<FlexProps & { token?: string; logout: () => void }> = ({ token, logout, ...props }) => {
  return (
    <Flex component='ul' my={0} ml='auto' gap='0.25rem' pl={0} style={{ listStyle: 'none' }} justify='center' direction='column' {...props}>
      <Center component='li'>
        <Button component='a' href='/' variant='subtle' color='white' fw={700} size='md'>
          Home
        </Button>
      </Center>
      <Center component='li'>
        <Button component='a' href='/' variant='subtle' color='white' fw={700} size='md'>
          Why this card?
        </Button>
      </Center>
      <Center component='li'>
        <Button component='a' href='/' variant='subtle' color='white' fw={700} size='md'>
          Pricing
        </Button>
      </Center>
      <Center component='li'>
        <Button component='a' href='/' variant='subtle' color='primary.8' c='primary' autoContrast fw={700} size='md'>
          {/* <Box component='span' c='primary'> */}
          Get Your Card
          {/* </Box> */}
        </Button>
      </Center>
      <Center component='li'>
        {token ? (
          <Button
            leftSection={<IconLogout style={{ height: '1.5em', width: '1.5em' }} />}
            onClick={logout}
            fw='bold'
            variant='filled'
            color='primary'
            autoContrast
            size='md'
          >
            Log out
          </Button>
        ) : (
          <Button
            component={Link}
            to='~/sign-in'
            leftSection={<IconLogin style={{ height: '1.5em', width: '1.5em' }} />}
            onClick={logout}
            fw='bold'
            variant='subtle'
            color='white'
            size='md'
          >
            Log in
          </Button>
          // <Link to='/sign-in'>
          //   <LoginIcon style={{ height: '1.5em', width: '1.5em', marginRight: 8 }} />
          //   Log in
          // </Link>
        )}
      </Center>
    </Flex>
  )
}

export const Navbar = ({ smaller = false }: { smaller?: boolean }) => {
  const { clearSession } = useContext(SessionContext)
  const { token, clearToken } = useContext(AuthContext)
  const [, navigate] = useLocation()
  const [collapsed, setCollapsed] = useState(true)

  const logout = () => {
    navigate('~/sign-in')
    clearToken()
    clearSession()
  }

  return (
    <Box component='nav' style={{ zIndex: 1000 }} pos='relative' pb={{ base: 70, md: smaller ? 90 : 120 }}>
      <Box component={NavbarBackground} h={{ base: 70, md: smaller ? 90 : 120 }} pos='fixed' top={0} w='100%' visibleFrom='md' />
      <Flex
        h={{ base: 70, md: smaller ? 90 : 120 }}
        w='100%'
        wrap='wrap'
        align='center'
        justify='space-between'
        bg={{ base: 'dark.9', md: 'transparent' }}
        pos='fixed'
        top={0}
      >
        <Container
          display='flex'
          pb={5}
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box component={CardNoirLogo} h={{ base: 42, md: 52 }} ml={5} my={{ base: 5, md: 0 }} />

          <Burger
            hiddenFrom='md'
            onClick={() => setCollapsed(!collapsed)}
            size='md'
            opened={!collapsed}
            aria-expanded={!collapsed}
            aria-label='Toggle navigation'
          />

          <Flex flex='auto' c='primary' fw='700' visibleFrom='md'>
            <Links token={token} logout={logout} direction='row' />
          </Flex>
          <Drawer
            hiddenFrom='md'
            opened={!collapsed}
            position='top'
            color='red'
            zIndex={1002}
            overlayProps={{ opacity: 0.25 }}
            onClose={() => setCollapsed(!collapsed)}
            closeOnClickOutside
            top={70}
            pos='fixed'
            styles={{
              inner: {
                top: 70,
              },
              content: {
                height: 'min-content',
                backgroundColor: 'var(--mantine-color-dark-7)',
              },
            }}
            withCloseButton={false}
          >
            <Links token={token} logout={logout} />
          </Drawer>
        </Container>
        <Box
          pos='absolute'
          bottom={0}
          w='100%'
          h={5}
          style={{
            backgroundColor: 'var(--mantine-color-primary-6)',
            background: 'linear-gradient(225deg, var(--mantine-color-primary-6), var(--mantine-color-error-6))',
          }}
        />
      </Flex>
    </Box>
  )
}

export default Navbar
