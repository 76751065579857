import dayjs from 'dayjs'
import { Transaction, TransactionType } from '../utils/interfaces'


export const getDate = (dateString: string) => {
  return dayjs(dateString).format('dddd Do MMMM, YYYY')
}


export const getTransactionType = (transaction: Transaction): TransactionType => {
  if (transaction.type === 'card-payment') {
    return transaction.reference.includes('ATM_WITHDRAWAL')
      ? TransactionType.ATM_WITHDRAWAL
      : TransactionType.CARD_PAYMENT
  }

  const baseType = transaction.reference.includes('INTERNAL_TRANSFER')
    ? TransactionType.INTERNAL_TRANSFER
    : TransactionType.BANK_TRANSFER

  const direction = transaction.amount >= 0 ? '_IN' : '_OUT'
  
  return (baseType + direction) as TransactionType
}

export const descriptionMap = (transactionType: TransactionType) => {
  switch (transactionType) {
    case TransactionType.INTERNAL_TRANSFER_OUT:
      return 'Internal transfer to'
    case TransactionType.INTERNAL_TRANSFER_IN:
      return 'Internal transfer from'
    case TransactionType.ATM_WITHDRAWAL:
      return 'Withdrawn at'
    case TransactionType.CARD_PAYMENT:
      return 'Spent at'
    case TransactionType.BANK_TRANSFER_IN:
      return 'Received from'
    case TransactionType.BANK_TRANSFER_OUT:
      return 'Sent to'
  }
}

export const properName = (transactionType: TransactionType) => {
  switch (transactionType) {
    case TransactionType.INTERNAL_TRANSFER_OUT:
    case TransactionType.INTERNAL_TRANSFER_IN:
      return 'Internal Transfer'
    case TransactionType.ATM_WITHDRAWAL:
      return 'ATM Withdrawal'
    case TransactionType.CARD_PAYMENT:
      return 'Card Payment'
    case TransactionType.BANK_TRANSFER_IN:
    case TransactionType.BANK_TRANSFER_OUT:
      return 'Money Transfer'
  }
}
