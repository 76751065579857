import { useLocalStorage } from '@mantine/hooks'
import dayjs from 'dayjs'
import { useState } from 'react'
// import mockedData from '../backend/mockedData'
import { User } from './interfaces'

// copied and adapted from fxbet

/** Hook that manages user authentication, and exposes current
 * authentication state, as well as function(s) to manage this
 * state. Typically used by top-level components that wish to
 * manage an authentication context - i.e. usually at the top-level
 * of the app. The return value is an object suitable to use as the
 * value for a `AuthContext.Provider`.
 */

export const useAuthManager = () => {
  // For authentication, we store / retrieve simple JWT (token)
  // in both localstorage and cookies. Changes to these are tracked
  // in top-level application state though
  //
  const [token, setToken, removeToken] = useLocalStorage<string | undefined>({ key: 'card-noir-token', getInitialValueInEffect: false })
  // console.log(token)
  const [lastLoggedIn, setLastLoggedIn, removeLastLoggedIn] = useLocalStorage<string | undefined>({ key: 'card-noir-last-logged-in' })
  const [user, setUser] = useState<User | undefined>()
  // const [user, setUser] = useState<User | undefined>(mockedData.user as User)

  const storeToken = (jwt: string) => {
    setToken(jwt)
    setLastLoggedIn(dayjs().format('DD MMMM YYYY, hh:mm A'))
  }

  const clearToken = () => {
    setToken(undefined)
    setUser(undefined)
    setLastLoggedIn(undefined)
    removeToken()
    removeLastLoggedIn()
  }

  return [token, storeToken, lastLoggedIn, clearToken, user, setUser] as [string, (jwt: string) => void, string, () => void, User, (user: User) => void]
}
