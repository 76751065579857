.accountHeaderWrapper {
  width: 100%;
  order: 0;
  background-color: var(--mantine-color-dark-8);
  border-radius: var(--mantine-radius-md);
  /* margin-bottom: 1rem; */

  @media (min-width: $mantine-breakpoint-xs) {
    /* margin-bottom: 0rem; */
  }

  @media (min-width: $mantine-breakpoint-md) {
    order: 3;
  }
}
