.flexContainer {
    flex-direction: column;
    gap: var(--mantine-spacing-xxxl);
    justify-content: center;
    flex-wrap: wrap;
}

.accountLabel {
  font-weight: bold;
  text-align: center;
}

.accountInfo {
  text-align: center;
  font-size: var(--mantine-font-size-md);
}

.account {
    background-color: var(--mantine-color-dark-8);
    border-radius: var(--mantine-radius-md);
    box-shadow:  0 0 5px 0 var(--mantine-primary-color-4), 0 0 20px 5px var(--mantine-primary-color-9);
    text-align: center;
    flex-direction: column;
    margin: 0 auto;
    justify-content: center;
}

.account:hover {
  background-color:var(--mantine-color-dark-7);
  transition: 0.2s;
  box-shadow:  0 0 5px 0 var(--mantine-color-gray-1), 0 0 20px 5px var(--mantine-color-gray-1);
}

@media (min-width: $mantine-breakpoint-md) {
  .flexContainer {
    flex-direction: row;
    column-gap: var(--mantine-spacing-sm);
  }

  .account {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    padding-top: var(--mantine-spacing-lg);
    padding-bottom: var(--mantine-spacing-lg);
  }
  
  .accountLabel {
    width: 8rem;
    text-align: left;
  }
}

.mobileNoShadow {
  box-shadow: var(--mantine-shadow-cardnoir);
  padding: var(--mantine-spacing-md);
  background-color: var(--mantine-color-dark-8);

  @media (max-width: $mantine-breakpoint-xs) {
    background-color: var(--mantine-color-dark-7);
    box-shadow: none;
    padding: var(--mantine-spacing-xs);
  }
}
