import { BoxProps, Divider, Paper } from '@mantine/core'
import React, { useContext } from 'react'
import ChatButton from '../../../components/ChatButton/ChatButton'
import { HeaderSecondary } from '../../../components/common/HeaderSecondary'
import { SessionContext } from '../../../contexts'
import Beneficiary from './Beneficiary'

const BeneficiaryList: React.FC<BoxProps & { setGroupFilter?: () => void }> = ({ setGroupFilter }) => {
  const { session } = useContext(SessionContext)

  return (
    <>
      <Paper radius='md' shadow='cardnoir' p='lg' bg='dark.8'>
        <HeaderSecondary c='nebulous.0' p={0} style={{ lineHeight: 1.2 }}>
          Beneficiaries
        </HeaderSecondary>
        <Divider size='sm' mt='md' color='primary.3' />
        <div>
          {session.beneficiaries.map((b, i) =>
            setGroupFilter ? (
              <Beneficiary data={b} isEven={i % 2 === 0} isLastInGroup={i === session.beneficiaries.length - 1} key={b.id} setGroupFilter={setGroupFilter} />
            ) : (
              <Beneficiary data={b} isEven={i % 2 === 0} isLastInGroup={i === session.beneficiaries.length - 1} key={b.id} />
            )
          )}
        </div>
      </Paper>

      <ChatButton />
    </>
  )
}

export default BeneficiaryList
