
.root {
  display: grid;
  grid-template-columns: auto;
  min-height: calc(100vh - 5rem);
}

.formColumn {
  width: '100%';
  margin-top: 16;
  border-left: 2pt solid var(--mantine-primary-color-3);
  padding-left: 24px;
}

@media (max-width: $mantine-breakpoint-md) {
  .hideOnDesktop {
    display: none;
  }
}
