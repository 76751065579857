import { Box, Text } from '@mantine/core'
import dayjs from 'dayjs'
import { getDate } from '../../../components/transactionUtils'
import { Transaction } from '../../../utils/interfaces'
import TransactionItem from './TransactionItem'
import classes from './TransactionTimeline.module.css'

const TransactionTimeline = ({
  transactions,
  numberOfCards = 1,
}: {
  transactions: Transaction[]
  numberOfCards: number
}) => {

  let availableSpace = numberOfCards * 192 + 48 * (numberOfCards - 1) - 25
  const displayedTransactions: { [key: string]: Transaction[] } = {}

  transactions.sort((f, s) => (dayjs(f.created_at).isBefore(dayjs(s.created_at)) ? 1 : -1))

  for (const tx in transactions) {
    if (displayedTransactions[getDate(transactions[tx].created_at)]) {
      availableSpace = availableSpace - 46
      if (availableSpace < 0) {
        break
      }
      displayedTransactions[getDate(transactions[tx].created_at)].push(transactions[tx])
    } else {
      availableSpace = availableSpace - 73.5
      if (availableSpace < 0) {
        break
      }
      displayedTransactions[getDate(transactions[tx].created_at)] = [transactions[tx]]
    }
  }

  return (
    <Box py='0.5rem' h='100%' px='1rem'>
      <Box style={{ borderLeft: '5px solid white' }} h='100%'>
        <Text inline size='sm' fw={600} pb='0.5rem' pl='1rem'>
          Most recent transactions
        </Text>
        {Object.keys(displayedTransactions).length === 0 ? (
          <Text inline size='sm' fw={600} pb='0.5rem' pl='1rem'>
            No transactions to show
          </Text>
        ) :
          Object.keys(displayedTransactions).map((date, i) => (
            <Box key={date}>
              <Box pl='1rem'>
                <Text
                  inline
                  py='md'
                  size='sm'
                  fw={600}
                  c='var(--mantine-color-primary-4)'
                  className={`${classes.dateWithMarker} ${i === 0 ? classes.firstDate : ''}`}
                >
                  {date}
                </Text>
              </Box>
              {displayedTransactions[date].map((transaction) => (
                <TransactionItem key={transaction.id} transaction={transaction} />
              ))}
            </Box>
          ))}
      </Box>
    </Box>
  )
}

export default TransactionTimeline
