import { ModalsProvider } from '@mantine/modals'
import { AuthContext, SessionContext } from './contexts'
import Router from './routes/Router'
import { useAuthManager } from './utils/useAuthManager'
import { useSessionManager } from './utils/useSessionManager'
import modals, { modalProps } from './components/modals'

const App = () => {
  const [session, dispatch, clearSession] = useSessionManager()
  const [token, setToken, lastLoggedIn, clearToken, user, setUser] = useAuthManager()

  return (
    <SessionContext.Provider value={{ session, dispatch, clearSession }}>
      <AuthContext.Provider value={{ token, lastLoggedIn, setToken, clearToken, user, setUser }}>
        <ModalsProvider modals={modals} modalProps={modalProps}>
          <Router />
        </ModalsProvider>
      </AuthContext.Provider>
    </SessionContext.Provider>
  )
}

export default App
