.root {
  width: 100%;
  padding-inline: 0.6875rem;
  margin-inline: auto;
  max-width: 25.875rem;

  @media (min-width: $mantine-breakpoint-md) {
    max-width: min(100vw - 6rem, 85.9375rem);
  }
}
