import React, { ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import { CSSGrid } from '../CSSGrid'
import AppFooter from './AppFooter'
import AppNavbar from './AppNavbar'

export const AppWrapper: React.FC<React.PropsWithChildren<{ title: string; appHeader: ReactNode }>> = ({ children, title, appHeader }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AppNavbar />
      <CSSGrid mih='calc(100vh - 3.25rem)' templateRows='auto 1fr auto'>
        {appHeader}
        {children}
        <AppFooter />
      </CSSGrid>
    </>
  )
}

export default AppWrapper
