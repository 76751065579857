import { ActionIcon, Box, Collapse, Divider, Flex } from '@mantine/core'
import dayjs from 'dayjs'
import { useState } from 'react'
import { IconArrowDropDown } from '../../../../assets/icons'
import { currencyFormat } from '../../../../backend/currencies'
import { CSSGrid } from '../../../../components/CSSGrid'
import TransactionIcon from '../../../../components/TransactionIcon'
import { descriptionMap, getDate, getTransactionType, properName } from '../../../../components/transactionUtils'
import { Transaction } from '../../../../utils/interfaces'
import classes from './Timeline.module.css'

const TransactionItem = ({ transaction }: { transaction: Transaction }) => {
  const [isOpened, setIsOpened] = useState(false)

  const transactionType = getTransactionType(transaction)

  return (
    <div className={classes.timelineItem} onClick={() => setIsOpened((v) => !v)}>
      <CSSGrid templateColumns='auto 1fr auto auto' py='sm' px='sm' alignItems='center' fz='sm' lh={1.3} gap='sm'>
        <TransactionIcon transactionType={transactionType} />

        <Box>
          <Box c='primary.3'>{descriptionMap(transactionType)}</Box>
          <Box fw='500'>{transaction.counterparty.name}</Box>
        </Box>
        <Flex w='max-content' fw='700' ta='right' gap='xxs'>
          {currencyFormat.format(transaction.amount) + '  ' + transaction.currency}
        </Flex>

        <ActionIcon variant='transparent' color='primary.6'>
          <Box component={IconArrowDropDown} h={12} style={{ transform: isOpened ? 'rotate(180deg)' : '', transition: 'transform 0.3s ease-in-out' }} />
        </ActionIcon>
      </CSSGrid>

      <Collapse in={isOpened}>
        <Box
          mx='sm'
          mb='sm'
          p='sm'
          fz='sm'
          c='nebulous.0'
          style={{
            border: '1pt dashed var(--mantine-color-primary-9)',
          }}
        >
          {transactionType.startsWith('INTERNAL_') ? (
            <CSSGrid templateColumns='1fr auto'>
              <div>Transaction status</div>
              <Box ta='right' tt='capitalize'>
                {transaction.status}
              </Box>
              <div>Transaction type</div>
              <Box ta='right'>{properName(transactionType)}</Box>
              <div>Estimated arrival</div>
              <Box ta='right'>{dayjs(transaction.created_at).format('D MMM YYYY')}</Box>
              <div>Reference</div>
              <Box ta='right'>{transaction.reference}</Box>
              <div>Transaction number</div>
              <Box ta='right'>{transaction.id}</Box>
            </CSSGrid>
          ) : transaction.amount > 0 ? (
            <CSSGrid templateColumns='1fr auto'>
              <div>Transaction status</div>
              <Box ta='right' tt='capitalize'>
                {transaction.status}
              </Box>
              <div>Transaction type</div>
              <Box ta='right'>{properName(transactionType)}</Box>
              <div>Estimated arrival</div>
              <Box ta='right'>{dayjs(transaction.created_at).format('D MMM YYYY')}</Box>
              <div>Reference</div>
              <Box ta='right'>{transaction.reference}</Box>
              <div>Transaction number</div>
              <Box ta='right'>{transaction.id}</Box>
            </CSSGrid>
          ) : (
            <CSSGrid templateColumns='1fr auto'>
              <div>Transaction status</div>
              <Box ta='right' tt='capitalize'>
                {transaction.status}
              </Box>
              <div>Transaction type</div>
              <Box ta='right'>{properName(transactionType)}</Box>
              <div>Date</div>
              <Box ta='right'>{dayjs(transaction.created_at).format('D MMM YYYY')}</Box>
              <div>Time (local)</div>
              <Box ta='right'>{dayjs(transaction.created_at).format('HH:mm')}</Box>
              <div>Transaction number</div>
              <Box ta='right'>{transaction.id}</Box>
            </CSSGrid>
          )}
        </Box>
      </Collapse>
      <Divider size='xs' color='nebulous.6' />
    </div>
  )
}

const Timeline = ({ transactions }: { transactions: Transaction[] }) => {
  const displayedTransactions: { [key: string]: Transaction[] } = {}
  transactions.sort((f, s) => (dayjs(f.created_at).isBefore(dayjs(s.created_at)) ? 1 : -1))

  for (const tx in transactions) {
    displayedTransactions[getDate(transactions[tx].created_at)] = [...(displayedTransactions[getDate(transactions[tx].created_at)] || []), transactions[tx]]
  }

  return (
    <Box pb='md' pl='xs'>
      <Box pos='relative'>
        <Box pos='absolute' left={-4} bottom={0} top={0} w={4} bg='nebulous.0' />
        {Object.keys(displayedTransactions).map((date) => (
          <div key={date}>
            <div className={classes.transactionItem}>
              <Box ml='md' pb='xxs'>
                {date}
              </Box>
            </div>
            <Divider size='xs' color='nebulous.6' ml='md' />
            {displayedTransactions[date].map((transaction, i) => (
              <TransactionItem key={transaction.id} transaction={transaction} />
            ))}
          </div>
        ))}
      </Box>
    </Box>
  )
}

export default Timeline
