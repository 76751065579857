import dayjs from 'dayjs'

export default {
  user: {
    id: '7e32b507-c666-4bcc-a216-a2541855ed29',
    first_name: 'The John',
    last_name: 'Wick',
    date_of_birth: '1992-10-29',
    email: 'tjw@assass.in',
    phone_number: '+230 5925 6854',
    status: 'activated',
    nationality: ['SE'],
    country_of_birth: 'SE',
    language: 'GB',
    promo_code: 'asd',
    agreed_to_tos: true,
    agreed_to_promotions: true,
    card_use: 'Other',
    card_funding: 'Other',
    address: {
      id: '22dd9f79-6884-40ee-ad73-54958b5d50d6',
      street1: 'Lor Sime 3',
      street2: 'Lakz',
      city: 'St Pierre',
      country_code: 'SE',
      postal_code: '81418',
    },
    shipping_address: {
      id: '9f7a2540-0d7e-4721-b3df-c64c4cae6ec1',
      street1: 'Lor Sime 4',
      street2: 'Lakz',
      city: 'St Pierre',
      country_code: 'SE',
      postal_code: '81418',
    },
    other_identifiers: [
      {
        id: 'eade0a63-1d90-48ae-a413-da2ce56a0f7b',
        external_id: '7cf1e633-7834-4d92-977a-2cee2b4f2ae3',
        source: 'integiro',
        type: 'individual',
        valid_from: '',
        valid_to: '',
      },
    ],
    accounts: [
      {
        id: '0e692011-a41e-4626-b993-7cdf5f0e25f5',
        individual_id: '7e32b507-c666-4bcc-a216-a2541855ed29',
        name: 'EUR SEPA Account',
        currency: 'EUR',
        balance: 42000,
        type: 'current',
        alias: '',
        created_at: '2022-05-19T08:09:04.000Z',
        updated_at: '2022-05-19T08:09:04.000Z',
        bank_details: {
          id: 'e5ee5a79-e9d0-49f0-b17f-50b09ab0edbc',
          owner_id: '0e692011-a41e-4626-b993-7cdf5f0e25f5',
          type: 'account',
          name: 'Intergiro Intl AB (publ)',
          payment_rail: 'sepa',
          bic: 'FTCSSESS',
          iban: 'SE6597700000010006395338',
          created_at: '2022-05-19T08:09:04.000Z',
          updated_at: '2022-05-19T08:09:04.000Z',
        },
        cards: [
          {
            id: '0f847849-97ba-482a-aa60-526fdb178719',
            display_name: 'The John Wick EUR',
            type: 'virtual',
            account_id: '0e692011-a41e-4626-b993-7cdf5f0e25f5',
            brand: 'visa',
            currency: 'EUR',
            exp_month: '12',
            exp_year: '2024',
            last4: '2756',
            region: 'europe',
            status: 'active',
            created_at: '2022-05-19T08:09:04.000Z',
            updated_at: '2022-05-19T08:09:04.000Z',
          },
          {
            id: 'xxxx1234-xxxx-xxxx-xxxx-xxxx1234xxxx',
            display_name: 'The John Wick EUR',
            type: 'physical',
            account_id: '0e692011-a41e-4626-b993-7cdf5f0e25f5',
            brand: 'visa',
            currency: 'EUR',
            exp_month: '12',
            exp_year: '2024',
            last4: '6666',
            region: 'europe',
            status: 'active',
            created_at: '2022-09-27T09:57:01.251Z',
            updated_at: '2022-09-27T09:57:01.251Z',
          },
        ],
      },
      {
        id: 'aaaa1234-aaaa-aaaa-aaaa-aaaa1234aaaa',
        individual_id: '7e32b507-c666-4bcc-a216-a2541855ed29',
        name: 'USD SEPA Account',
        currency: 'USD',
        balance: 2000,
        type: 'current',
        alias: null,
        created_at: '2022-09-27T09:57:01.194Z',
        updated_at: '2022-09-27T09:57:01.194Z',
        bank_details: {
          id: '45ae21eb-437e-4e4b-94bb-1788fdabd8e6',
          owner_id: 'aaaa1234-aaaa-aaaa-aaaa-aaaa1234aaaa',
          type: 'account',
          name: 'Intergiro Intl AB (publ)',
          payment_rail: 'sepa',
          bic: 'FTCSSESS',
          iban: 'SE3197700000010009395772',
          created_at: '2022-09-27T09:57:01.251Z',
          updated_at: '2022-09-27T09:57:01.251Z',
        },
        cards: [
          {
            id: 'cccc1234-xxxx-xxxx-xxxx-xxxx1234xxxx',
            display_name: 'The John Wick USD',
            type: 'physical',
            account_id: 'aaaa1234-aaaa-aaaa-aaaa-aaaa1234aaaa',
            brand: 'visa',
            currency: 'USD',
            exp_month: '01',
            exp_year: '2025',
            last4: '7777',
            region: 'europe',
            status: 'active',
            created_at: '2022-09-27T09:57:01.251Z',
            updated_at: '2022-09-27T09:57:01.251Z',
          },
        ],
      },
    ],
    lastRefresh: dayjs(),
  },
  beneficiaries: [
    {
      id: 'bene1',
      name: 'James Bond',
      alias: 'James Bond',
      payment_type: 'sepa', // || swift || sepa || cardnoir
      entity_type: 'individual', // || individual || corporate
      currency: 'EUR',
      email: 'jb@iso.uk',
      status: 'active', // || inactive || pending || active
      account_details: {
        name: 'James Bond',
        account: {
          bic: 'FTCSSESS',
          iban: 'SE37977000000100082',
          type: 'sepa',
        },
      },
      country: 'GB',
    },
    {
      id: 'bene2',
      name: 'Cloudy Hosting',
      alias: 'Cloudy Hosting',
      payment_type: 'sepa',
      entity_type: 'corporate',
      currency: 'EUR',
      email: 'billing@cloudyhosting.com',
      status: 'active',
      account_details: {
        name: 'Cloudy Hosting',
        account: {
          bic: 'FTCRRTXX',
          iban: 'DE37988004400165282',
          type: 'sepa',
        },
      },
      country: 'GB',
    },
    {
      id: 'bene3',
      name: 'Richard Summer',
      alias: 'Richard Summer',
      payment_type: 'swift',
      entity_type: 'individual',
      currency: 'EUR',
      email: 'jsmith@smiths.com',
      status: 'active',
      account_details: {
        name: 'John Smith',
        account: {
          bic: 'FTCXXEXX',
          iban: 'GR37857000000100742',
          type: 'swift',
        },
      },
      country: 'GB',
    },
    {
      id: 'bene4',
      name: 'Timothy Harland',
      alias: 'Timothy Harland',
      payment_type: 'swift',
      entity_type: 'individual',
      currency: 'EUR',
      email: 'jsmith@smiths.com',
      status: 'active',
      account_details: {
        name: 'Timothy Harland',
        account: {
          bic: 'FTCWWEXX',
          iban: 'CS95632000000847522',
          type: 'swift',
        },
      },
      country: 'GB',
    },
  ],
  transactions: [
    {
      account_id: '0e692011-a41e-4626-b993-7cdf5f0e25f5',
      amount: -100,
      counterparty: {
        name: 'James Bond',
        account_details: {
          iban: 'SE37977000000100082',
          type: 'sepa',
        },
      },
      created_at: '2021-09-03T12:35:22.900Z',
      currency: 'EUR',
      id: 'dAC3YuVVz1',
      reference: 'A new pair of shoes',
      request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      account_id: '0e692011-a41e-4626-b993-7cdf5f0e25f5',
      amount: -144.25,
      counterparty: {
        name: 'John Smith',
        account_details: {},
      },
      created_at: '2021-09-04T06:23:22.900Z',
      currency: 'EUR',
      id: 'dAC3YuVVz2',
      reference: 'Thanks for the tips',
      request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
      status: 'completed',
      type: 'card-payment',
    },
    {
      account_id: '0e692011-a41e-4626-b993-7cdf5f0e25f5',
      amount: -544.25,
      counterparty: {
        name: 'Bagatelle Mall',
        account_details: {},
      },
      created_at: '2021-09-04T17:48:22.900Z',
      currency: 'EUR',
      id: 'dAC3YuVVz3',
      reference: 'Survival Kit',
      request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
      status: 'completed',
      type: 'card-payment',
    },
    {
      account_id: 'aaaa1234-aaaa-aaaa-aaaa-aaaa1234aaaa',
      amount: -5445.25,
      counterparty: {
        name: 'Richard Summer',
        account_details: {
          iban: 'GR37857000000100742',
          type: 'swift',
        },
      },
      created_at: '2021-08-13T13:11:22.900Z',
      currency: 'EUR',
      id: 'dAC3YuVVz4',
      reference: 'Nada',
      request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
      status: 'completed',
      type: 'bank-transfer',
    },
    // {
    //   account_id: '0e692011-a41e-4626-b993-7cdf5f0e25f5',
    //   amount: -422.0,
    //   counterparty: {
    //     name: 'Timothy Harland',
    //     account_details: {
    //       iban: 'CS95632000000847522',
    //       type: 'swift',
    //     },
    //   },
    //   created_at: '2021-08-12T14:15:22.900Z',
    //   currency: 'EUR',
    //   id: 'dAC3sdVVzo',
    //   reference: 'Nada 2',
    //   request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
    //   status: 'completed',
    //   type: 'bank-transfer',
    // },
    {
      account_id: 'aaaa1234-aaaa-aaaa-aaaa-aaaa1234aaaa',
      amount: -400.0,
      counterparty: {
        account_details: {},
        name: 'Cascavelle',
      },
      created_at: '2021-08-13T05:00:22.900Z',
      currency: 'EUR',
      id: 'dAC3sdVVz1',
      reference: 'ATM_WITHDRAWAL',
      request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
      status: 'completed',
      type: 'card-payment',
    },
    {
      account_id: 'aaaa1234-aaaa-aaaa-aaaa-aaaa1234aaaa',
      amount: -15.25,
      counterparty: {
        account_details: {
          iban: 'DE37988004400165282',
          type: 'sepa',
        },
        name: 'Cloudy Hosting',
      },
      created_at: '2021-06-05T15:14:22.900Z',
      currency: 'EUR',
      id: 'dAC3YuVVz5',
      reference: 'More storage space',
      request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
      status: 'completed',
      type: 'card-payment',
    },
    {
      account_id: 'aaaa1234-aaaa-aaaa-aaaa-aaaa1234aaaa',
      amount: -15.25,
      counterparty: {
        account_details: {
          iban: 'DE37988004400165282',
          type: 'sepa',
        },
        name: 'Cloudy Hosting',
      },
      created_at: '2021-07-05T14:15:22.900Z',
      currency: 'EUR',
      id: 'dAC3YuVVzo',
      reference: 'More storage space',
      request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
      status: 'completed',
      type: 'card-payment',
    },
    {
      account_id: 'aaaa1234-aaaa-aaaa-aaaa-aaaa1234aaaa',
      amount: -15.25,
      counterparty: {
        account_details: {
          iban: 'DE37988004400165282',
          type: 'sepa',
        },
        name: 'Cloudy Hosting',
      },
      created_at: '2021-08-05T14:15:22.900Z',
      currency: 'EUR',
      id: 'dAC3YuVVz6',
      reference: 'More storage space',
      request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
      status: 'completed',
      type: 'card-payment',
    },
    {
      account_id: 'aaaa1234-aaaa-aaaa-aaaa-aaaa1234aaaa',
      amount: -15.25,
      counterparty: {
        account_details: {
          iban: 'DE37988004400165282',
          type: 'sepa',
        },
        name: 'Cloudy Hosting',
      },
      created_at: '2021-09-05T14:15:22.900Z',
      currency: 'EUR',
      id: 'dAC3YuVVz7',
      reference: 'More storage space',
      request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
      status: 'completed',
      type: 'card-payment',
    },
    {
      account_id: '0e692011-a41e-4626-b993-7cdf5f0e25f5',
      amount: -200.0,
      counterparty: {
        account_details: {},
        name: 'Phydra',
      },
      created_at: '2021-09-04T14:15:22.900Z',
      currency: 'EUR',
      id: 'dAC3YuVVz8',
      reference: 'Soap',
      request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
      status: 'completed',
      type: 'card-payment',
    },
    {
      account_id: '0e692011-a41e-4626-b993-7cdf5f0e25f5',
      amount: -350.25,
      counterparty: {
        account_details: {},
        name: 'Intermart',
      },
      created_at: '2021-09-04T14:15:22.900Z',
      currency: 'EUR',
      id: 'dAC3YuVVz9',
      reference: 'SUPERMARKET',
      request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
      status: 'completed',
      type: 'card-payment',
    },
    {
      account_id: '0e692011-a41e-4626-b993-7cdf5f0e25f5',
      amount: -54.25,
      counterparty: {
        account_details: {},
        name: 'Pharmacy St Jean',
      },
      created_at: '2021-08-13T14:15:22.900Z',
      currency: 'EUR',
      id: 'dAC3YuVV10',
      reference: 'Survival Kit',
      request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
      status: 'completed',
      type: 'card-payment',
    },
    {
      account_id: '0e692011-a41e-4626-b993-7cdf5f0e25f5',
      amount: -5000.0,
      counterparty: {
        account_id: 'aaaa1234-aaaa-aaaa-aaaa-aaaa1234aaaa',
        name: 'Joanne Blanda',
      },
      created_at: '2021-08-13T14:15:22.900Z',
      currency: 'EUR',
      id: 'dAC3YuVV11',
      reference: 'INTERNAL_TRANSFER',
      request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
      status: 'completed',
      type: 'bank-transfer',
    },
  ],
}
