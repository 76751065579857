import { Carousel } from '@mantine/carousel'
import { Box, Button, Collapse, Flex, Group, Stack } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useContext, useState } from 'react'
import { IconArrowDropDown, IconCopy, IconTick, IconViewInfoEye } from '../../../assets/icons'
import Card from '../../../components/Card'
import { SessionContext } from '../../../contexts'
import { Account } from '../../../utils/interfaces'
import AccountDetails from './AccountDetails'
import classes from './AccountOverview.module.css'
import TransactionTimeline from './TransactionTimeline'

const AccountOverview = ({ isFirst, account }: { isFirst: boolean; account: Account }) => {
  const { session } = useContext(SessionContext)
  const transactions = session.transactions.filter((t) => t.account_id === account.id)
  const [opened, { toggle }] = useDisclosure(false)

  const cards = account.cards

  const accentColor = cards[0]?.type === 'physical' ? 'var(--mantine-color-primary-3)' : 'var(--mantine-color-cyan-3)'
  const [activeCard, setActiveCard] = useState(-1)

  return (
    <div className={classes.root}>
      <AccountDetails account={account} accent={accentColor} className={classes.accountDetails} />
      <Flex w='100%' className={classes.cards} visibleFrom='md' justify='center'>
        <Carousel
          slideSize='100%'
          slideGap='md'
          classNames={{ root: classes.carousel, control: classes.controls, indicators: classes.indicators }}
          styles={{ controls: { zIndex: 0 }, indicator: { backgroundColor: accentColor } }}
          withIndicators={cards.length > 1}
          onSlideChange={(index) => setActiveCard(index)}
          withControls={cards.length > 1}
        >
          {cards.map((card, i) => (
            <Carousel.Slide key={i}>
              <Flex p={{ base: '0.4rem', md: 'xxl' }} className={classes.cardItem} justify='center'>
                <Card w={{ base: 400, md: 400 }} card={card} />
                <Stack justify='space-around' gap={10} ml={{ base: 'lg', md: 'xxl' }}>
                  <Button
                    h='auto'
                    style={{ border: '2pt solid white' }}
                    variant='vertical'
                    color='red'
                    disabled={!isFirst}
                    leftSection={
                      <IconTick
                        style={{
                          height: '2.2em',
                          width: '2.2em',
                          color: 'var(--mantine-color-primary-text)',
                        }}
                      />
                    }
                  >
                    main
                    <br />
                    card
                  </Button>
                  <Button
                    style={{ border: '2pt solid white' }}
                    variant='vertical'
                    leftSection={<IconCopy style={{ height: '1.6em', width: '1.6em', color: 'white' }} />}
                  >
                    copy
                  </Button>
                  <Button
                    p='0.15rem'
                    color='transparent'
                    fz='sm'
                    style={{ border: '2pt solid white' }}
                    variant='vertical'
                    leftSection={<IconViewInfoEye style={{ height: '1.6em', width: '1.6em', color: 'white' }} />}
                  >
                    show
                  </Button>
                </Stack>
              </Flex>
            </Carousel.Slide>
          ))}
        </Carousel>
      </Flex>

      <Flex className={classes.cards} justify='space-between' hiddenFrom='md'>
        {cards.map((card, i) => (
          <Flex key={i} p={{ base: '0.4rem', md: 'xxl' }} className={classes.cardItem}>
            <Card w={{ base: 265, md: 400 }} card={card} />
            <Stack justify='space-around' gap={10} ml={{ base: 'xxl', md: 'xxxl' }}>
              <Button
                h='auto'
                style={{ border: '2pt solid white' }}
                variant='vertical'
                color='red'
                disabled={!isFirst}
                leftSection={
                  <IconTick
                    style={{
                      height: '2.2em',
                      width: '2.2em',
                      color: 'var(--mantine-color-primary-text)',
                    }}
                  />
                }
              >
                main
                <br />
                card
              </Button>
              <Button
                style={{ border: '2pt solid white' }}
                variant='vertical'
                leftSection={<IconCopy style={{ height: '1.6em', width: '1.6em', color: 'white' }} />}
              >
                copy
              </Button>
              <Button
                p='0.15rem'
                color='transparent'
                fz='sm'
                style={{ border: '2pt solid white' }}
                variant='vertical'
                leftSection={<IconViewInfoEye style={{ height: '1.6em', width: '1.6em', color: 'white' }} />}
              >
                show
              </Button>
            </Stack>
          </Flex>
        ))}
      </Flex>

      <Flex className={classes.miniCardsRow} visibleFrom='md'>
        {cards.map((card, i) => (
          <Box key={i} className={classes.miniCard} mb='md' style={{ transform: activeCard === i ? 'scale(1.2)' : 'none' }}>
            <Card w={{ base: '80px', md: '110px' }} card={card} accentOverrideColor='--mantine-color-gray-0' />
          </Box>
        ))}
      </Flex>

      <Box className={classes.timeline} mt={{ base: 0, md: 'sm' }}>
        <Group justify='center' mb='md' mt='lg'>
          <Button
            size='md'
            w={{ base: '100%', md: 'auto' }}
            c='white'
            variant='outline'
            color='primary.5'
            onClick={toggle}
            leftSection={<IconArrowDropDown height='1em' style={{ transform: opened ? 'rotate(180deg)' : '' }} />}
          >
            View most recent transactions
          </Button>
        </Group>
        <Collapse in={opened} transitionDuration={200} transitionTimingFunction='linear'>
          <TransactionTimeline transactions={transactions} numberOfCards={cards.length} />
        </Collapse>
      </Box>
    </div>
  )
}

export default AccountOverview
