import parsePhoneNumber, { CountryCode } from 'libphonenumber-js'
import { InternationalPhoneNumber } from './PhoneNumber'

/**
 * Checks if the given phone number object is valid.
 */
export const isValidPhoneNumber = ({ countryCode, localPhoneNumber }: Partial<InternationalPhoneNumber>): boolean => {
  if (localPhoneNumber === undefined) {
    return false
  }
  if (localPhoneNumber.match(/^[^a-zA-Z]*$/) == null) {
    return false
  }
  try {
    const number = parsePhoneNumber(localPhoneNumber, { defaultCountry: countryCode as CountryCode })
    // console.log(number)
    if (number && number.isValid()) {
      return true
    }
    return false
  } catch (e) {
    return false
  }
}

export const formatValidPhoneNumber = ({ countryCode, localPhoneNumber }: Partial<InternationalPhoneNumber>) => {
  // assuming phone number is valid
  if (localPhoneNumber === undefined) {
    return ''
  }
  try {
    const number = parsePhoneNumber(localPhoneNumber, { defaultCountry: countryCode as CountryCode })
    if (number) return number.formatInternational()
    return ''
  } catch (e) {
    return ''
  }
}

export const parseToInternationalPhoneNumber = (phoneNumber: string, countryCode?: string) => {
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber, { defaultCountry: countryCode as CountryCode })
  if (parsedPhoneNumber) {
    return {
      countryCode: parsedPhoneNumber.country as string,
      diallingCode: '+' + parsedPhoneNumber.countryCallingCode,
      localPhoneNumber: parsedPhoneNumber.nationalNumber,
      isValid: true,
      formattedPhoneNumber: parsedPhoneNumber.formatInternational(),
    } as InternationalPhoneNumber
  }
  return undefined
}
