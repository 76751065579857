import produce from 'immer'
import { Session } from './interfaces'

export default (session: Session, { type, ...payload }: { [key: string]: never }) => {
  const nextState = produce(session, (draft) => {
    switch (type) {
      case 'setOnboardingUser':
        draft.onboardingUser = payload.onboardingUser
        break
      case 'resetLastSteps':
        draft.onboardingUser.status = 'onboarding_profile_confirmed'
        break
      case 'addBeneficiary':
        draft.beneficiaries = [...draft.beneficiaries, payload.beneficiary]
        break
      case 'addTransaction':
        draft.transactions = [...draft.transactions, payload.transaction]
        break
    }
  })
  localStorage.setItem('card-noir-session', JSON.stringify(nextState))
  return nextState
}
