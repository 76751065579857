import { Box, BoxProps } from '@mantine/core'
import Container from './Container'
import classes from './AppFooter.module.css'
import { IconMasterCard, IconVisaCard } from '../../assets/icons'

export const AppFooter = (props: BoxProps) => {
  return (
    <Box {...props} className={classes.root + ' ' + props.className}>
      <Container className={classes.container}>
        <div className={classes.linksWrapper}>
          <a>PRIVACY POLICY</a>
          <a>TERMS &amp; CONDITIONS</a>
        </div>
        <div className={classes.legalWrapper}>
          © Card Noir. All rights reserved. Card Noir, (address)(registration country). Card Noir is authorised to ...Ex velit dolore voluptate eu et. Voluptate
          et esse aliquip qui id eiusmod culpa Lorem veniam ut. Culpa sint exercitation et mollit nisi amet sunt eiusmod fugiat sunt labore.
        </div>
        <div className={classes.iconWrapper}>
          <IconMasterCard className={classes.masterCardIcon} />
          <IconVisaCard className={classes.visaIcon} />
        </div>
      </Container>
    </Box>
  )
}

export default AppFooter
