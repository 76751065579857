import { Text, Flex, FlexProps, Image } from '@mantine/core'
import React from 'react'

export const PageEncryptedReminder: React.FC<FlexProps> = (props) => {
  return (
    <Flex justify='center' align='center' c='dark.0' my='1rem' py='1rem'{...props}>
      <Image
        fit='initial'
        w={20}
        h={20}
        mr={6}
        src='data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE4AAABYCAYAAABbJcGbAAAEfklEQVR4nO2c0VEbMRCGFw8FXDogFXCpIKYDqABTAXYFCRUAFcSpAKjATgUcFcTp4J7Ia0bJ3oxynE+r1WolH/pnPHiYO53u80paraQ9+v36Csqa4+cUACr8bmsLAC0AvOD3rXYFKdICVwPANQCcIywfGYiPAHAPAI1GZSmKDc4Aux2wKq6M9a1yADiLVG6FwJ4FoQGW9Yxl+1quqGJYnLGyb/g3pozVXaWyPmlwBtZG0RpM/3eWAp5kU9WGBvisjYJ1v5EUuBTQOiWBJ9FUQyq+ww9gszNlneDHVw022zb0hSg6Fijjiye0Fn2ytQWtLwNugb4f1YprrMvKr/o8hVpcje4BVTcAcOdhFQbaEoFQ9UljsAgFtyH6aaGjn08fusVnRVUIOKq1SbkMPvCiW13IqHpNvE7Kz2o8LIlaN7ZCwJ0TrrkR/uUbLNMlSt2CxAU3JzSZFgcCaVEGl6FwlahCwLl0H8mn6twZiTqyxQV3SrhmHbHelLIpdWSLC87VTHcjzq2EKOVHnf7FaqoxoVGfkWVTdUllvphSscC9HMbr8xUL3ORVwDFVwDFVwDFVwDFVwDFVwDG1b83hBEMzn5lTl0u8N6Yo6xybgf8Z5/wH7kdhz3D6EeAK4/vLyC+di+4wvuc907HBJVvcTSzWsqLdxz28Q2iA7/zge1MHbhE7mpC55siArK6p/mSunk9JZqD4SH2fGZrqe4cGyIDcVc3eeRPti8zi2GN1/NBVESyK7LNSN91E31KgoPkeh5ilMuViqoBjqoBjqoBjqoBjqoBjqoBjqoBjqoBjqoBjqoBjSuKACFdzXNSprcl3g5/vEQILLW5I/NXbX2dP/smLNyaQ+ZVwAOOIX983qjBU7QrhGHAXuW4Z026qNUabKXGvOV6b5TqIJrgKDwD7rNNy7lGRJrgl03rqHNd5NcGFnHaJflLGV1rgKAdKxhT9wIevtMBJdPBZDRJa4CQ696wGCC1wEr5YVv6cFjipY5fZSAvcNtBi2oRru4NdhKY7QjnxF+PeEHVb3277ZWiCu2M2tybSuVeX7P2Cy/5uJk1wLeZC8mmynHskNLTJ8r98UdqT/Aa3UlH6qy1eqz0ojO1M3XQ7u1IEMrusEGcYH7PBNPi/M82sNZZc23m7kFiVMpCZWxpH6h7ovxl1Suj8n3w3jr8UcP7QzGC1nhK4esjfcogFDRIv1kjKTh9U4Qu6xIYGE1ke7OdcWqDPNaYgaDABcPsSVY3BC4YGBw7Old1rCJ4INJgAOFdw04YnBg0OfHBYYxog1wpYNzmvpaDBBEbVFVqS6xyWzzktJzSYyKhKelHpsqgWJ3awwkONR4bVK8+muK8M8g9gNt0sGR63hkxk5IPHc0IOKntb7Szjc1qUs1e2uElMWU19hg/SSFvGke/qfeu5NYzdP3aDAyVhZwpdMp65IwZBgwYVO5kBNRmytrg5fcdmFsEjse2OXOS26Ivi7lRq9kRJRNwXG1zXuaZYihvTIuDo+2MPnpjPty9ld2imG2k9Bf6g3cxBxlEGgD9aWAssQOpmpgAAAABJRU5ErkJggg=='
      />
      <Text ta='center' fz='xs'>This page has 256-bit SSL encryption for your security</Text>
    </Flex>
  )
}
