import { Box, BoxProps, Button, Flex, Group, Text } from '@mantine/core'
import React, { useContext, useState } from 'react'
import { useLocation } from 'wouter'
import { CardNoirLogoBackground, IconLogout, IconProfileInformation, IconStatement } from '../../assets/icons'
import { AuthContext, SessionContext } from '../../contexts'
import { Menu, MenuItem } from '../common/Menu'
import { openModalForSendFunds } from '../modals/SendFunds'
import Container from './Container'

// const useStyles = createStyles((theme) => ({
//   navbar: {
//     zIndex: 1000,
//     padding: 0,
//     position: 'relative',
//     height: '3rem',
//   },
//   wrapper: {
//     height: '3rem',
//     width: '100%',
//     display: 'flex',
//     flexWrap: 'wrap',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//     position: 'fixed',
//     top: 0,
//     backgroundColor: theme.fn.themeColor('dark.7'),
//   },
//   container: {
//     display: 'flex',
//     paddingTop: 'unset',
//     paddingBottom: '0.3rem',
//     flexDirection: 'row',
//     flexWrap: 'wrap',
//     alignItems: 'center',
//     position: 'relative',
//     width: '100%',
//     [theme.other.onDesktop]: {
//       height: '3rem',
//       justifyContent: 'center',
//     },
//   },
//   logo: {
//     display: 'inline-block',
//     width: '13.5rem',
//     paddingTop: '0.3rem',
//   },
//   leftsideButtons: {
//     position: 'absolute',
//     right: 0,
//     [theme.other.onDesktop]: {
//       right: 'unset',
//       left: 0,
//     },
//   },
//   profileButton: {
//     [theme.fn.smallerThan('md')]: {
//       display: 'none',
//     },
//   },
//   rightsideButtons: {
//     position: 'absolute',
//     right: 0,
//     [theme.fn.smallerThan('md')]: {
//       display: 'none',
//     },
//   },
//   statementCentreButton: {
//     [theme.fn.smallerThan(1050)]: {
//       display: 'none',
//     },
//   },
//   time: {
//     display: 'inline-block',
//     flexDirection: 'column',
//     textAlign: 'right',
//     width: '8.75rem',
//     color: theme.fn.themeColor('nebulous.0'),
//     fontSize: theme.fontSizes.sm,
//     fontWeight: 700,
//   },
// }))

export const AppNavbar: React.FC<BoxProps> = (props) => {
  const { clearSession } = useContext(SessionContext)
  const { clearToken } = useContext(AuthContext)
  const [opened, setOpened] = useState(false)
  const [, navigate] = useLocation()

  const logout = () => {
    setOpened(false)
    navigate('~/sign-in')
    clearToken()
    clearSession()
  }

  return (
    <Box component='nav' pos='relative' h='3.25rem' {...props}>
      <Box w='100%' h='3.25rem' pos='fixed' top={0} bg={{ base: 'dark.8', md: '#1a1a1a' }} style={{ zIndex: 1000 }}>
        <Container h='100%'>
          <Flex w='100%' align='center' justify={{ md: 'center' }} pos='relative' h='100%'>
            <Group gap={4} wrap='nowrap' pos='absolute' right={{ base: '0' }} left={{ md: 0 }}>
              <Menu
                zIndex={1001}
                opened={opened}
                setOpened={setOpened}
                title={
                  <Text size='h4' lh={1} ta='center' w='100%' c='dark.0'>
                    Menu &amp; Settings
                  </Text>
                }
                shadow='rgb(100 100 100 / 10%) 0px 0px 0px 1px, rgb(247 246 246 / 20%) 3px 0px 12px, rgb(247 246 246 / 20%) 2px 0px 24px, rgb(247 246 246 / 10%) 1px 0px 32px'
                menuButtonTitle={
                  <Text fz='sm' lh={1.2} c='nebulous.0' ta='left' p='0'>
                    Menu &amp;
                    <br />
                    Settings
                  </Text>
                }
                // menuButtonProps={{
                //   variant: 'borderless',
                //   size: 'md',
                //   textColor: 'white',
                //   leftIconColor: 'primary',
                // }}
              >
                <MenuItem
                  title='Card Overview'
                  description='Dashboard of all your balances'
                  borderPosition='top and bottom'
                  borderColor='primary'
                  textColor='primary'
                  onClick={() => {
                    navigate('~/member')
                    setOpened(false)
                  }}
                />
                <MenuItem title='+ Annual Fees' description='Pay your annual profile fees' textColor='error.4' borderPosition='bottom' borderColor='error.4' />
                <MenuItem title='Transact' description='Send, load and transfer funds' borderColor='primary' borderPosition='bottom' textColor='primary'>
                  <MenuItem
                    title='Send funds'
                    description='Pay a beneficiary'
                    align='right'
                    borderColor='primary'
                    borderPosition='bottom'
                    textColor='primary'
                    onClick={openModalForSendFunds()}
                  />
                  <MenuItem
                    title='Load funds'
                    description='Add funds to one of your cards'
                    align='right'
                    borderColor='primary'
                    borderPosition='bottom'
                    textColor='primary'
                  />
                  <MenuItem
                    title='Transfer funds internally'
                    description='Move funds between your cards'
                    align='right'
                    borderColor='primary'
                    borderPosition='bottom'
                    textColor='primary'
                  />
                  <MenuItem
                    title='Direct Debit Orders'
                    description='Manage all direct debit orders'
                    align='right'
                    borderColor='primary'
                    borderPosition='bottom'
                    textColor='primary'
                  />
                  <MenuItem
                    title='Outstanding Authorizations'
                    description='Transactions that need release'
                    align='right'
                    borderColor='primary'
                    borderPosition='bottom'
                    textColor='primary'
                  />
                  <MenuItem
                    title='Dispute Center'
                    description='Transaction disputes are logged here'
                    align='right'
                    borderColor='primary'
                    borderPosition='bottom'
                    textColor='primary'
                  />
                </MenuItem>
                <MenuItem
                  title='Beneficiary Centre'
                  description='Add and manage beneficiaries'
                  textColor='primary'
                  borderColor='primary'
                  borderPosition='bottom'
                  onClick={() => {
                    navigate('~/member/beneficiary-centre')
                    setOpened(false)
                  }}
                />
                <MenuItem
                  title='Statement Centre'
                  description='Analytics, transaction history, downloads'
                  textColor='primary'
                  borderColor='primary'
                  borderPosition='bottom'
                  onClick={() => {
                    navigate('~/member/statement-centre')
                    setOpened(false)
                  }}
                />
                <MenuItem
                  title='Profile Information'
                  description='Update info, app personalization'
                  borderColor='primary'
                  textColor='primary'
                  borderPosition='bottom'
                >
                  <MenuItem
                    title='Update Profile Information'
                    description='Passwords, manage info, address'
                    align='right'
                    borderColor='white'
                    borderPosition='bottom'
                    textColor='white'
                  />
                  <MenuItem
                    title='Load funds'
                    description='Add funds to one of your cards'
                    align='right'
                    borderColor='white'
                    borderPosition='bottom'
                    textColor='white'
                  />
                  <MenuItem
                    title='App Personalization'
                    description='Language, notifications, theme, etc'
                    align='right'
                    borderColor='white'
                    borderPosition='bottom'
                    textColor='white'
                  />
                  <MenuItem
                    title='Security Centre'
                    description='Connected devices currently logged in'
                    align='right'
                    borderColor='white'
                    borderPosition='bottom'
                    textColor='white'
                  />
                </MenuItem>
                <MenuItem
                  title='+ Add More Cards'
                  description='Order physical or add virtual cards'
                  textColor='success'
                  borderPosition='bottom'
                  borderColor='white'
                />
                <MenuItem
                  title='+ Activate a Physical Card'
                  textColor='error.4'
                  description='Initiate a physical card you received'
                  borderPosition='bottom'
                  borderColor='white'
                />
                <MenuItem
                  title='Mail, News and Security Info'
                  description='Latest news and product updates'
                  textColor={'white'}
                  borderPosition='bottom'
                  borderColor='white'
                />
                <MenuItem
                  title='Contact and Help Center'
                  description='Reach out for assistance and give feedback'
                  textColor={'white'}
                  borderPosition='bottom'
                  borderColor='white'
                />
                <MenuItem title='Legal Documents' description='Dashboard for all legal information' textColor={'white'} />
                <MenuItem title='Log Out' description='Log out of your profile' borderPosition='top and bottom' textColor='primary' borderColor='primary' />
              </Menu>
              <Button visibleFrom='md' leftSection={<Box component={IconProfileInformation} h='1.8em' />} variant='subtle' color='primary.6'>
                <Text fz='sm' lh={1.2} c='nebulous.0' ta='left'>
                  Your
                  <br />
                  Profile
                </Text>
              </Button>
            </Group>
            <Group gap={4} wrap='nowrap' pos='absolute' right={0} visibleFrom='md'>
              <Button
                leftSection={<Box component={IconStatement} h='2em' />}
                variant='subtle'
                color='primary.6'
                onClick={() => {
                  navigate('~/member/statement-centre')
                  setOpened(false)
                }}
              >
                <Text fz='sm' lh={1.2} c='nebulous.0' ta='left'>
                  Statement
                  <br />
                  Centre
                </Text>
              </Button>
              <Button leftSection={<Box component={IconLogout} h='1.9em' />} variant='subtle' color='primary.6' onClick={logout} autoContrast>
                <Text fz='sm' lh={1.2} c='nebulous.0' ta='left'>
                  Log
                  <br />
                  Out
                </Text>
              </Button>
            </Group>
            <Flex>
              <Box component={CardNoirLogoBackground} w={{ base: '11rem', md: '13.5rem' }} />
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}

export default AppNavbar
