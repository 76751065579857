import dayjs from 'dayjs'
import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'wouter'
import API from '../backend/api'
import { AuthContext, SessionContext } from '../contexts'
import { LoadingScreen } from './Router'

/**
 *
 * @prop {boolean} forceRedirect - Redirect to user's dashboard if user is fully onboarded (status: activated).
 * @returns
 */
const Authenticator: React.FC<React.PropsWithChildren<{ forceRedirect: boolean }>> = ({ children, forceRedirect = false }) => {
  const [, navigate] = useLocation()
  const { dispatch } = useContext(SessionContext)
  const [isLoading, setIsLoading] = useState(true)
  const [loadingError, setLoadingError] = useState()

  const { user, token, setUser, clearToken } = useContext(AuthContext)

  useEffect(() => {
    /** If user is not set yet,
     * or user's last refresh was more than 45min ago
     * or user doesn't have an account yet (i.e. still onboarding) */
    if (
      user === undefined ||
      // TODO: uncomment to force check user status after session expires || or to use mocked details
      (user?.lastRefresh !== undefined && dayjs().diff(user.lastRefresh, 'minute') > 45) ||
      (user.accounts.length === 0 && user.status === 'activated')
    ) {
      if (token === undefined) {
        clearToken()
        navigate('~/sign-in', { state: { errorMessage: 'Your session has expired. Please sign in again.' } })
      } else {
        API.getUser({ token, clearToken })
          .then((u) => {
            setIsLoading(false)
            setUser({ ...u, lastRefresh: dayjs() })
            if (u.status !== 'activated') {
              dispatch({
                type: 'setOnboardingUser',
                onboardingUser: u,
              } as never)
              switch (u.status) {
                case 'onboarding_started':
                  navigate('~/sign-up/verified/profile')
                  break
                case 'onboarding_profile_created':
                  navigate('~/sign-up/verified/confirm-order')
                  break
                case 'onboarding_profile_confirmed':
                case 'onboarding_payment_successful':
                case 'onboarding_payment_pending':
                case 'onboarding_payment_failed':
                case 'onboarding_kyc_data_provided':
                case 'pending_activation':
                  navigate('~/sign-up/verified/last-steps')
                  break
              }
            } else if (forceRedirect) {
              navigate('~/member')
            }
          })
          .catch((err) => {
            if (err.message === 'Session expired') {
              clearToken()
              navigate('~/sign-in', { state: { errorMessage: 'Your session has expired. Please sign in again.' } })
            } else {
              // console.log(err, isLoading, user)
              // navigate('~/sign-in', { state: { errorMessage: 'Your session has expired. Please sign in again.' } })
              setLoadingError(err)
            }
          })
      }
    } else {
      setIsLoading(false)
    }
  }, [user, user?.status, token, setUser, forceRedirect, dispatch, navigate, clearToken])

  return loadingError ? (
    <LoadingScreen>We&apos;ve met with an unexpected error while loading the page. Please try again later.</LoadingScreen>
  ) : isLoading || user === undefined ? (
    <LoadingScreen />
  ) : (
    children
  )
}

export default Authenticator
