.root {
  position: relative;
  width: 100%;
  max-width: 800px;
}

.card {
  position: absolute;
  top: 0;
  left: 0;
}

.card text {
  white-space: pre;
}

.cardImage {
  width: 100%;
  border-radius: 16;
}
