const currencies = {
  EUR: {
    symbol: '€',
    regionName: 'Euro',
    countryCodeForFlag: 'eu',
  },
  GBP: {
    symbol: '£',
    regionName: 'United Kingdom',
    countryCodeForFlag: 'gb',
  },
  USD: {
    symbol: '$',
    regionName: 'United States',
    countryCodeForFlag: 'us',
  },
  SEK: {
    symbol: 'kr',
    regionName: 'Sweden Krona',
    countryCodeForFlag: 'se',
  },
}
export default currencies

export const currencyFormat = new Intl.NumberFormat(new Intl.NumberFormat().resolvedOptions().locale, {
  minimumFractionDigits: 2,
})