import { Box, CloseButton, ScrollArea, Title } from '@mantine/core'
import { CSSGrid, CSSGridProps } from '../CSSGrid'

interface ModalWrapperProps {
  onClose: () => void
  title?: string
  themeColor?: string
  footer?: React.ReactNode
}

const ModalWrapper: React.FC<CSSGridProps & ModalWrapperProps> = ({ title = '', onClose, children, footer, ...props }) => {
  return (
    <Box py='xs'>
      <CSSGrid templateColumns='1fr auto' alignItems='center' mt='xs' mb='md' px='xs' {...props}>
        <Title order={2} size='h2' c='primary' ta='center'>
          {title}
        </Title>
        <CloseButton size='lg' onClick={onClose} variant='subtle' />
      </CSSGrid>

      <ScrollArea.Autosize offsetScrollbars mah={{ md: 'calc(100dvh - (5dvh * 2) - 8rem)' }} ml='sm' px={{ md: 'xs' }}>
        <Box bd='2pt solid var(--mantine-color-primary-3)' style={{ borderRadius: 'var(--mantine-radius-md)' }} p='md'>
          {children}
        </Box>
        {footer && (
          <Box h='2rem' mt='md'>
            {footer}
          </Box>
        )}
      </ScrollArea.Autosize>
    </Box>
  )
}

export default ModalWrapper
