import { Title, TitleProps } from '@mantine/core'
import React from 'react'

export const Header: React.FC<React.PropsWithChildren<TitleProps>> = ({ children, ...props }) => {
  return (
    <Title order={1} c='primary' fw={600} ta='center' mt='2rem' mb='1rem' size={'h1'} p='0.5rem' {...props}>
      {children}
    </Title>
  )
}
export default Header
