import { ActionIcon, Box, Button, CopyButton, Divider, Flex, Text } from '@mantine/core'
import {
  IconCardFreeze,
  IconCardPIN,
  IconCloseDeleteNo,
  IconCopy,
  IconEdit,
  IconFundsCard,
  IconFundsLoad,
  IconFundsSend,
  IconFundsTransfer,
  IconReplacementCard,
} from '../../../../assets/icons'
import { CSSGrid } from '../../../../components/CSSGrid'
import { openModalForSendFunds } from '../../../../components/modals/SendFunds'
import formatIBAN from '../../../../utils/formatIBAN'
import { Account } from '../../../../utils/interfaces'

const CardOptions = ({ account }: { account: Account }) => {
  const accentColor = account.cards[0].type === 'virtual' ? 'cyan.3' : 'primary.3'

  return (
    <Box mt={{ base: 'xs', md: 0 }}>
      <CSSGrid px={{ md: 'md' }} py='md' templateColumns='1fr 1fr' rowGap='md' columnGap='sm'>
        <Button variant='cardnoir-white' leftSection={<IconFundsLoad height='1.5rem' />} color={accentColor}>
          <div>Load funds</div>
          <div>receive and add</div>
        </Button>
        <Button
          variant='cardnoir-white'
          leftSection={<IconFundsSend height='1.5rem' />}
          color={accentColor}
          onClick={openModalForSendFunds({ selectedAccount: account.id })}
        >
          <Box>Send funds</Box>
          <Box>pay a beneficiary</Box>
        </Button>
        <Button variant='cardnoir-white' leftSection={<IconFundsTransfer height='1.5rem' />} color={accentColor}>
          <Box>Transfer</Box>
          <Box>to another card</Box>
        </Button>
        <Button variant='cardnoir-white' leftSection={<IconFundsCard height='1.5rem' />} color={accentColor}>
          <Box>Card limits</Box>
          <Box>adjust and manage</Box>
        </Button>
      </CSSGrid>
      <Divider size='sm' color={accentColor} />
      <Flex p='md' justify='space-between' align='center'>
        <Flex gap='xs' align='center'>
          <Text lh='1.2' fz='sm' fw={500}>
            IBAN
          </Text>
          <Text lh='1.2' fz='sm' c='dark.0'>
            {formatIBAN(account.bank_details.iban)}
          </Text>
        </Flex>
        <CopyButton value={account.bank_details.iban.replaceAll(' ', '')}>
          {({ copied, copy }) => (
            <ActionIcon size='sm' variant='outline' color={copied ? accentColor : 'dark.0'} onClick={copy}>
              <IconCopy height='1.2rem' />
            </ActionIcon>
          )}
        </CopyButton>
      </Flex>
      <Divider size='sm' color={accentColor} />
      <CSSGrid px={{ md: 'md' }} py='md' templateColumns='1fr 1fr' rowGap='md' columnGap='sm'>
        <Button variant='cardnoir-white' leftSection={<IconEdit height='1.5rem' />} color='success.3'>
          <Box>Update name</Box>
          <Box>use an alias</Box>
        </Button>
        <Button variant='cardnoir-white' leftSection={<IconCardPIN height='1.5rem' />} color='success.3'>
          <Box>Manage PIN</Box>
          <Box>view and modify</Box>
        </Button>
        <Button variant='cardnoir-white' leftSection={<IconCardFreeze height='1.5rem' />} color='success.3'>
          <Box>Freeze card</Box>
          <Box>temporarily</Box>
        </Button>
        <Button variant='cardnoir-white' leftSection={<IconCloseDeleteNo height='1.5rem' />} color='success.3'>
          <Box>Cancel card</Box>
          <Box>permanently</Box>
        </Button>
        <Button variant='cardnoir-white' leftSection={<IconReplacementCard height='1.5rem' />} color='success.3'>
          <Box>Replacement</Box>
          <Box>order a new card</Box>
        </Button>
        <Button variant='cardnoir-white' leftSection={<IconFundsCard height='1.5rem' />} color='success.3'>
          <Box>+ Joint card</Box>
          <Box>add a second card</Box>
        </Button>
      </CSSGrid>
    </Box>
  )
}

export default CardOptions
