import { Box, Button, Flex, Paper, Select, Stack, Text } from '@mantine/core'
import { useLocation } from 'wouter'
import { IconActivateCard, IconCopy, IconTick, IconViewInfoEye } from '../../../../assets/icons'
import { currencyFormat } from '../../../../backend/currencies'
import { CSSGrid } from '../../../../components/CSSGrid'
import Card from '../../../../components/Card'
import { HeaderSecondary } from '../../../../components/common/HeaderSecondary'
import { Account, User } from '../../../../utils/interfaces'
import classes from './CardsOverview.module.css'

const Welcome = ({ accounts }: { accounts: Account[] }) => {
  const [, navigate] = useLocation()
  return (
    <Box>
      <Flex
        align='center'
        mb='1.5rem'
        pb='0.5rem'
        h={64}
        style={{
          borderBottom: '2pt solid var(--mantine-color-primary-6)',
        }}
      >
        <HeaderSecondary w='100%' ta='center' fz='xl' p={0} c='nebulous.0'>
          Statement Centre
        </HeaderSecondary>
      </Flex>
      <Text ta='center' c='nebulous.0' px='xs' pb='md'>
        Welcome to the Statement Centre
        <br />
        <br />
        Select a card to search, narrow and refine your statement history
      </Text>

      <Select
        w='100%'
        color='white'
        data={[
          { value: 'all', label: 'Showing all accounts' },
          ...accounts.map((account) => {
            return { value: account.id, label: account.name }
          }),
        ]}
        value={'all'}
        onChange={(id) => {
          navigate('~/member/statement-centre/' + (id === 'all' ? '' : id), { replace: true })
        }}
      />
    </Box>
  )
}

const CardsOverview = ({ account, user }: { account?: Account; user: User }) => {
  return account ? (
    <Flex direction='column' gap='xxl' px={0} py={0}>
      {account.cards.map((card, i) => (
        <CSSGrid templateColumns='1fr auto' style={{ order: 1 }} key={card.id} gap='md' pl={{ md: 4 }} pt={{ md: 4 }} p='md'>
          <Card card={card} w={300} />
          <Stack gap='sm' justify='end' pb='0.3rem'>
            <Button
              h='auto'
              p='0.2rem'
              color='transparent'
              fz='sm'
              style={{
                border: '2pt solid white',
              }}
              variant='vertical'
              leftSection={<IconTick style={{ height: '1.9em', width: '1.9em', color: 'var(--mantine-color-primary-6)' }} />}
            >
              main
              <br />
              card
            </Button>

            <Button
              h='auto'
              p='0.15rem'
              color='transparent'
              fz='sm'
              style={{
                border: '2pt solid white',
              }}
              variant='vertical'
              leftSection={<IconCopy style={{ height: '1.6em', width: '1.6em', color: 'white' }} />}
            >
              copy
            </Button>
            <Button
              h='auto'
              p='0.15rem'
              color='transparent'
              fz='sm'
              style={{
                border: '2pt solid white',
              }}
              variant='vertical'
              leftSection={<IconViewInfoEye style={{ height: '1.6em', width: '1.6em', color: 'white' }} />}
            >
              show
            </Button>
          </Stack>
        </CSSGrid>
      ))}

      <Box px={{ md: 'md' }} mt={{ md: 'xs' }} style={{ order: 1 }}>
        <Button variant='cardnoir-white' leftSection={<IconActivateCard height='1em' />} color='error.4' size='md'>
          <Box>Got your card? Activate it here</Box>
          <Box>activate a physical card after receiving it</Box>
        </Button>
      </Box>

      <div className={classes.accountHeaderWrapper}>
        <HeaderSecondary p='0rem 1rem' ta='left' visibleFrom='md'>
          {account.name}
        </HeaderSecondary>
        <Flex align='center' justify='space-between' p='0.375rem 1rem' fz='md'>
          <span>Balance</span>
          <Box component='span' fz='lg' fw={900} c='primary'>
            {currencyFormat.format(account.balance) + ' ' + account.currency}
          </Box>
        </Flex>
      </div>
    </Flex>
  ) : (
    <Box mih={270} w={360}>
      <Welcome accounts={user?.accounts} />
    </Box>
  )
}

export default CardsOverview
