.root {
  background-color: var(--mantine-color-dark-8);
  border-radius: var(--mantine-radius-md);
  box-shadow: var(--mantine-shadow-cardnoir);
  padding: 2rem;
  display: grid;
  grid-template-areas:
    'accountdetails'
    'cards';
  grid-template-columns: 1fr;
  margin: var(--mantine-spacing-xl) 0;
}

.cardItem {
  padding: 0;
  margin-bottom: var(--mantine-spacing-xl);
}

div .cards {
  margin-top: var(--mantine-spacing-xxl);
  gap: var(--mantine-spacing-md);
  flex-direction: column;
  flex-wrap: nowrap;
}

div .accountDetails {
  grid-area: accountdetails;
  grid-template-areas:
    'accountName'
    'options'
    'balance';
  display: grid;
  row-gap: var(--mantine-spacing-lg);
  grid-template-columns: 1fr;
}

.cardOptions {
  grid-area: options;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: var(--mantine-spacing-xxl);
  justify-content: space-between;
}

.accountName {
  grid-area: accountName;
}

.balance {
  grid-area: balance;
  padding-top: var(--mantine-spacing-xxs);
}

.miniCardsRow {
  display: flex;
  justify-content: center;
  gap: var(--mantine-spacing-xxxl);
  margin-top: var(--mantine-spacing-xl);
}

@media (min-width: $mantine-breakpoint-md) {
  .root {
    grid-template-areas:
      'accountdetails'
      'cards';
    grid-template-columns: 1fr;
  }

  div .carousel {
    width: 100%;
    height: auto;
    justify-content: center;
  }

  div .indicators {
      bottom: 0;
  }

  .cardOptions {
    display: flex;
    grid-template-columns: repeat(4, minmax(10rem, 1fr));
    gap: 1.5rem;
  }

  div .cards {
    grid-area: cards;
    flex-wrap: wrap;
    margin-top: var(--mantine-spacing-lg);
    margin-bottom: var(--mantine-spacing-lg);
    overflow: hidden;
  }

  .cardItem {
    margin-bottom: 0;
  }
}
