.dateWithMarker {
  border-block: 1px dashed var(--mantine-color-primary-4);
  position: relative;
}

.dateWithMarker::before {
  content: '';
  width: 17px;
  display: block;
  height: 17px;
  border-radius: 50%;
  position: absolute;
  left: -27px;
  top: 3px;
  background-color: var(--mantine-color-primary-6);
}

.firstDate::before {
  top: -24px;
}
