import { Center } from '@mantine/core'
import { PropsWithChildren, Suspense, lazy, useEffect } from 'react'
import { Route, Switch, useLocation } from 'wouter'
import { IconLoading } from '../assets/icons'
import Navbar from '../components/layout/Navbar'
import Authenticator from './Authenticator'
import SignIn from './SignIn'
import BeneficiaryCentre from './authenticated/BeneficiaryCentre'
import Dashboard from './authenticated/Dashboard'
import StatementCentre from './authenticated/StatementCentre'
import ForgotPassword from './user/ForgotPassword'
import ResetPassword from './user/ResetPassword'
const SignUp = lazy(() => import('./onboarding/SignUp'))
const VerifyEmail = lazy(() => import('./onboarding/VerifyEmail'))
const CompleteSignUp = lazy(() => import('./onboarding/CompleteSignUp'))
const CompleteProfile = lazy(() => import('./onboarding/CompleteProfile'))
const ConfirmOrder = lazy(() => import('./onboarding/ConfirmOrder'))
const LastSteps = lazy(() => import('./onboarding/LastSteps'))
const PaymentCallback = lazy(() => import('./onboarding/PaymentCallback'))
const KYCCallback = lazy(() => import('./onboarding/KYCCallback'))

export const LoadingScreen: React.FC<PropsWithChildren> = ({ children }) => (
  <>
    <Navbar smaller={true} />
    <Center mih='calc(100vh - 3rem)'>{children ? children : <IconLoading height='64px' style={{ animation: 'spin 2.5s infinite linear' }} />}</Center>
  </>
)

const ScrollToTop = () => {
  const [location] = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return null
}

const Router = () => {
  // const { token } = useContext(AuthContext)

  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route path='/sign-up' nest>
          <Suspense fallback={<LoadingScreen />}>
            <Route path='awaiting-verification' component={VerifyEmail} />
            {/* if i understood properly, once our API is available, this token will be used to fetch info submitted on previous screen */}
            <Route path='verify/:token/:email' component={CompleteSignUp} />
            {/* TODO: Authenticator loads user session info if not present and redirects base on user account status */}
            <Route path='verified' nest>
              <Authenticator forceRedirect={true}>
                <Switch>
                  <Route path='profile' component={CompleteProfile} />
                  <Route path='confirm-order' component={ConfirmOrder} />
                  <Route path='last-steps' component={LastSteps} />
                </Switch>
              </Authenticator>
            </Route>
            <Route path='/' component={SignUp} />
            <Route path='kyc-callback/:id' component={KYCCallback} />
            <Route path='payment-callback' component={PaymentCallback} />
          </Suspense>
        </Route>

        <Route path='/member' nest>
          <Authenticator forceRedirect={false}>
            <Switch>
              <Route path='/' component={Dashboard} />

              <Route path='statement-centre' component={StatementCentre} />
              <Route path='statement-centre/:id' component={StatementCentre} />
              <Route path='beneficiary-centre' component={BeneficiaryCentre} />
            </Switch>
          </Authenticator>
        </Route>
        <Route path='/user' nest>
          <Route path='forgot-password' component={ForgotPassword} />
          <Route path='reset-password/:token/:email' component={ResetPassword} />
        </Route>

        <Route path='sign-in' component={SignIn} />
        {/* FIXME - better handling for 404? Currently all path that doesn't match above will go to either Home or Sign-In page */}
        {/* <Route component={() => (token ? <Redirect replace to='/member' /> : <SignIn />)} /> */}
        <Route component={SignIn} />
      </Switch>
    </>
  )
}

export default Router
