import IconFASave from './FA_save.svg'
import IconActivateCard from './Icon_Activate_Card.svg'
import IconArchive from './Icon_Archive.svg'
import IconArrowBack from './Icon_Arrow_Back_1.svg'
import IconArrowBackTwo from './Icon_Arrow_Back_2.svg'
import IconArrowDropDown from './Icon_Arrow_Drop_Down.svg'
import IconBeneficiaryAdd from './Icon_Beneficiary_Add.svg'
import IconBeneficiaryCardNoir from './Icon_Beneficiary_CARDN.svg'
import IconBeneficiarySEPA from './Icon_Beneficiary_SEPA.svg'
import IconBeneficiarySWIFT from './Icon_Beneficiary_SWIFT.svg'
import IconCardFreeze from './Icon_Card_Freeze.svg'
import IconCardLimits from './Icon_Card_Limits.svg'
import IconEdit from './Icon_Card_Name_Edit.svg'
import IconCardOptions from './Icon_Card_Options.svg'
import IconCardPIN from './Icon_Card_PIN.svg'
import IconCloseDeleteNo from './Icon_Close_Delete_No.svg'
import IconCopy from './Icon_Copy.svg'
import IconFundsCard from './Icon_Funds_Card.svg'
import IconFundsLoad from './Icon_Funds_Load.svg'
import IconFundsSend from './Icon_Funds_Send.svg'
import IconFundsTransfer from './Icon_Funds_Transfer.svg'
import IconHelpQuestion from './Icon_Help_Question.svg'
import IconLogout from './Icon_Log_Out.svg'
import IconMasterCard from './Icon_Master_Card_SVG.svg'
import IconMenu from './Icon_Menu.svg'
import IconPDF from './Icon_PDF.svg'
import IconPlusOrderCard from './Icon_Plus_Order_Card.svg'
import IconProfileInformation from './Icon_Profile_Information_SVG.svg'
import IconReplacementCard from './Icon_Replacement_Card.svg'
import IconSearch from './Icon_Search.svg'
import IconSocialFacebook from './Icon_Social_Facebook_SVG.svg'
import IconSocialInstagram from './Icon_Social_Instagram_SVG.svg'
import IconSocialTwitter from './Icon_Social_Twitter_SVG.svg'
import IconStatement from './Icon_Statement.svg'
import IconStatementAnalytics from './Icon_Statement_Analytics.svg'
import IconStatementSaveExport from './Icon_Statement_Save_Export.svg'
import IconSunglasses from './Icon_Sunglasses.svg'
import IconTick from './Icon_Tick_Yes.svg'
import IconViewInfoEye from './Icon_View_Info_Eye.svg'
import IconVisaCard from './Icon_Visa_SVG.svg'
import CardNoirLogoBackground from './Logo_Card Noir_Background.svg'
import CardNoirLogo from './card_noir_logo.svg'
import IconBeneficiaryNameBookmark from './icon-beneficiary-name-bookmark.svg'
import IconLoading from './icon-funds-convert-forex.svg'
import IconLogin from './login.svg'

export {
  CardNoirLogo,
  CardNoirLogoBackground,
  IconActivateCard,
  IconArchive,
  IconArrowBack,
  IconArrowBackTwo,
  IconArrowDropDown,
  IconBeneficiaryAdd,
  IconBeneficiaryCardNoir,
  IconBeneficiaryNameBookmark,
  IconBeneficiarySEPA,
  IconBeneficiarySWIFT,
  IconCardFreeze,
  IconCardLimits,
  IconCardOptions,
  IconCardPIN,
  IconCloseDeleteNo,
  IconCopy,
  IconEdit,
  IconFASave,
  IconFundsCard,
  IconFundsLoad,
  IconFundsSend,
  IconFundsTransfer,
  IconHelpQuestion,
  IconLoading,
  IconLogin,
  IconLogout,
  IconMasterCard,
  IconMenu,
  IconPDF,
  IconPlusOrderCard,
  IconProfileInformation,
  IconReplacementCard,
  IconSearch,
  IconSocialFacebook,
  IconSocialInstagram,
  IconSocialTwitter,
  IconStatement,
  IconStatementAnalytics,
  IconStatementSaveExport,
  IconSunglasses,
  IconTick,
  IconViewInfoEye,
  IconVisaCard,
}
