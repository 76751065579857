import { Box, BoxProps, Button, Group, Text } from '@mantine/core'
import { useLocation } from 'wouter'
import { IconCardOptions, IconFundsLoad, IconFundsSend, IconFundsTransfer } from '../../../assets/icons'
import { currencyFormat } from '../../../backend/currencies'
import { HeaderSecondary } from '../../../components/common/HeaderSecondary'
import { openModalForSendFunds } from '../../../components/modals/SendFunds'
import { Account } from '../../../utils/interfaces'
import classes from './AccountOverview.module.css'

const AccountDetails = ({ account, accent, ...rest }: { account: Account; accent: string } & BoxProps) => {
  const [, navigate] = useLocation()

  return (
    <Box {...rest}>
      <HeaderSecondary p={0} ta='left' display='inline-block' w='20rem' className={classes.accountName}>
        {account.name}
      </HeaderSecondary>

      <Box className={classes.cardOptions}>
        <Button
          variant='cardnoir-white'
          size='md'
          color={accent}
          ta='left'
          leftSection={
            <IconFundsLoad
              style={{
                color: 'white',
              }}
            />
          }
          c='white'
        >
          <Box fw='bold'>Load funds</Box>
          <Box>receive and add</Box>
        </Button>
        <Button
          variant='cardnoir-white'
          size='md'
          color={accent}
          ta='left'
          leftSection={<IconFundsSend style={{ color: 'white' }} />}
          c='white'
          onClick={openModalForSendFunds({ selectedAccount: account.id })}
        >
          <Box fw='bold'>Send funds</Box>
          <Box>pay a beneficiary</Box>
        </Button>
        <Button variant='cardnoir-white' size='md' color={accent} ta='left' leftSection={<IconFundsTransfer style={{ color: 'white' }} />} c='white'>
          <Box fw='bold'>Transfer</Box>
          <Box>to another card</Box>
        </Button>
        <Button
          variant='cardnoir'
          size='md'
          color='var(--mantine-color-success-2)'
          style={{ buttonInner: { display: 'none' } }}
          leftSection={<IconCardOptions style={{ color: 'var(--mantine-color-success-5)' }} />}
          c='white'
          ta='left'
          onClick={() => {
            navigate('~/member/statement-centre/' + account.id)
          }}
        >
          <Box fw='bold'>Card Options</Box>
          <Box>statement, IBAN</Box>
        </Button>
      </Box>

      <Box className={classes.balance}>
        <Group
          wrap='nowrap'
          gap={0}
          justify='space-between'
          align='center'
          py='sm'
          style={{
            borderTop: `2pt solid ${accent}`,
            borderBottom: `2pt solid ${accent}`,
          }}
        >
          <Text fw={600}>Balance</Text>
          <Text fz='1.25rem' span fw={600} c='primary' inline>
            {`${currencyFormat.format(account.balance)} ${account.currency}`}
          </Text>
        </Group>
      </Box>
    </Box>
  )
}
export default AccountDetails
