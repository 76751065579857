import { DeepPartial, User } from '../utils/interfaces'

const env = process.env.NODE_ENV || 'development'

const API_VERSION = 'v1'
const API_BASE_URL = env === 'development' ? `http://localhost:8080/${API_VERSION}` : `https://api.${env === 'staging' ? 'dev.' : ''}cardnoir.eu/${API_VERSION}`

type AuthOptions = { token: string; clearToken: () => void }

export default {
  authenticateUser: async function ({ email, password }: { email: string; password: string }) {
    const resp = await fetch(`${API_BASE_URL}/user/authenticate`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      credentials: 'include',
      body: JSON.stringify({ email, password }),
    })

    if (resp.status === 200) {
      return resp.json()
    } else if (resp.status === 400) {
      const error = await resp.json()
      // send back the first error for the bad request
      throw new Error(error.errors[0].msg)
    } else if (resp.status === 401) {
      const error = await resp.json()
      throw new Error(error.message)
    }
  },

  getUser: async function ({ token, clearToken }: AuthOptions) {
    const resp = await fetch(`${API_BASE_URL}/user`, {
      mode: 'cors',
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: token,
      },
      // body: JSON.stringify({ time: Date.now() }),
    })

    if (resp.status === 200) {
      return resp.json()
    } else if (resp.status === 400) {
      throw new Error('There is an issue with the request.')
    } else if (resp.status === 401) {
      clearToken()
      throw new Error('Your session has expired. Please login again.')
    } else if (resp.status === 500) {
      console.error(await resp.json())
      throw new Error('An unexpected server error has occurred.')
    } else {
      const errorMessage = await resp.text()
      throw new Error(errorMessage)
    }
  },

  sendPasswordResetLink: async function ({ email }: { email: string }) {
    const resp = await fetch(`${API_BASE_URL}/user/send-reset-password-link`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ email }),
    })

    if (resp.status === 200) {
      return resp.json()
    } else if (resp.status === 401) {
      const error = await resp.json()
      throw new Error(error.message)
    }
  },

  resetPassword: async function ({ emailToken, email, password }: { emailToken: string; email: string; password: string }) {
    const resp = await fetch(`${API_BASE_URL}/user/reset-password`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ token: emailToken, email, password }),
    })

    if (resp.status === 200) {
      return resp.json()
    } else if (resp.status === 400) {
      const error = await resp.json()

      if (error.error_type === 'INVALID_TOKEN') throw new Error('This link has expired. Please start sign up process again.')

      console.error(error)
      throw new Error('There is an issue with the request.')
    } else if (resp.status === 500) {
      console.error(await resp.json())
      throw new Error('An unexpected server error has occurred.')
    } else {
      const errorMessage = await resp.text()
      throw new Error(errorMessage)
    }
  },

  signup: async function (user: DeepPartial<User>) {
    const resp = await fetch(`${API_BASE_URL}/onboarding/signup`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        date_of_birth: user.date_of_birth,
        promo_code: user.promo_code,
        address: user.address,
        nationality: user.nationality,
      }),
    })

    if (resp.status === 200) {
      return resp.json()
    } else if (resp.status === 400) {
      console.error(await resp.json())
      throw new Error('There is an issue with the request.')
    } else if (resp.status === 409) {
      console.error(await resp.json())
      throw new Error('This email address is already associated with an account.')
    } else if (resp.status === 500) {
      console.error(await resp.json())
      throw new Error('An unexpected server error has occurred.')
    } else {
      const errorMessage = await resp.text()
      throw new Error(errorMessage)
    }
  },

  completeSignUp: async function ({ emailToken, email, password }: { emailToken: string; email: string; password: string }) {
    const resp = await fetch(`${API_BASE_URL}/onboarding/complete-signup`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ token: emailToken, email, password }),
    })

    if (resp.status === 200) {
      return resp.json()
    } else if (resp.status === 400) {
      const error = await resp.json()

      if (error.error_type === 'INVALID_TOKEN') {
        throw new Error('This link has expired. Please start sign up process again.')
      }

      if (error.error_type === 'EMAIL_ALREADY_VERIFIED') {
        throw new Error('This email address is already associated with a verified account. Please log in directly.')
      }

      console.error(error)
      throw new Error('There is an issue with the request.')
    } else if (resp.status === 500) {
      console.error(await resp.json())
      throw new Error('An unexpected server error has occurred.')
    } else {
      const errorMessage = await resp.text()
      throw new Error(errorMessage)
    }
  },

  completeProfile: async function ({ token, clearToken }: AuthOptions, user: DeepPartial<User>) {
    const resp = await fetch(`${API_BASE_URL}/onboarding/complete-profile`, {
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: token,
      },
      body: JSON.stringify(user),
    })

    if (resp.status === 200) {
      return resp.json()
    } else if (resp.status === 400) {
      console.error(await resp.json())
      throw new Error('There is an issue with the request.')
    } else if (resp.status === 401) {
      clearToken()
      throw new Error('Your session has expired. Please login again.')
    } else if (resp.status === 500) {
      console.error(await resp.json())
      throw new Error('An unexpected server error has occurred.')
    } else {
      const errorMessage = await resp.text()
      throw new Error(errorMessage)
    }
  },

  confirmOrder: async function ({ token, clearToken }: AuthOptions, user: User) {
    const resp = await fetch(`${API_BASE_URL}/onboarding/confirm-order`, {
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: token,
      },
      body: JSON.stringify(user),
    })

    if (resp.status === 200) {
      return resp.json()
    } else if (resp.status === 400) {
      throw new Error('There is an issue with the request.')
    } else if (resp.status === 401) {
      clearToken()
      throw new Error('Your session has expired. Please login again.')
    } else {
      console.error(await resp.json())
      throw new Error('An unexpected server error has occurred')
    }
  },

  createPaymentIntentForMembershipFee: async function ({ token, clearToken }: AuthOptions) {
    const resp = await fetch(`${API_BASE_URL}/onboarding/create-membership-payment-intent`, {
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: token,
      },
    })

    if (resp.status === 200) {
      return resp.json()
    } else if (resp.status === 400) {
      console.error(await resp.json())
      throw new Error('There is an issue with the request.')
    } else if (resp.status === 401) {
      clearToken()
      throw new Error('Your session has expired. Please login again.')
    } else {
      console.error(await resp.json())
      throw new Error('An unexpected server error has occurred')
    }
  },
  initiateKYC: async function ({ token, clearToken }: AuthOptions) {
    const resp = await fetch(`${API_BASE_URL}/onboarding/initiate-kyc`, {
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: token,
      },
    })
    if (resp.status === 200) {
      return resp.json()
    } else if (resp.status === 400) {
      console.error(await resp.json())
      throw new Error('There is an issue with the request.')
    } else if (resp.status === 401) {
      clearToken()
      throw new Error('Your session has expired. Please login again.')
    } else {
      console.error(await resp.json())
      throw new Error('An unexpected server error has occurred')
    }
  },

  // simulatePayment: async function (id) {
  //   const resp = await fetch(`${API_BASE_URL}/onboarding/simulate-payment`, {
  //     mode: 'cors',
  //     credentials: 'include',
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json;charset=utf-8',
  //     },
  //     body: JSON.stringify({ id }),
  //   })

  //   if (resp.status === 200) {
  //     return resp.json()
  //   } else if (resp.status === 400) {
  //     const error = await resp.json()
  //     console.error(error)
  //     throw new Error('There is an issue with the request.')
  //   } else if (resp.status === 500) {
  //     console.error(await resp.json())
  //     throw new Error('An unexpected server error has occurred.')
  //   } else {
  //     const errorMessage = await resp.text()
  //     throw new Error(errorMessage)
  //   }
  // },
  simulateOnboarding: async function (id: string) {
    const resp = await fetch(`${API_BASE_URL}/onboarding/simulate-onboarding-kyc`, {
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ id }),
    })

    if (resp.status === 200) {
      return resp.json()
    } else if (resp.status === 400) {
      const error = await resp.json()
      console.error(error)
      throw new Error('There is an issue with the request.')
    } else if (resp.status === 500) {
      console.error(await resp.json())
      throw new Error('An unexpected server error has occurred.')
    } else {
      const errorMessage = await resp.text()
      throw new Error(errorMessage)
    }
  },
}
