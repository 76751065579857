import { Box, Group, Text } from '@mantine/core'
import { currencyFormat } from '../../../backend/currencies'
import TransactionIcon from '../../../components/TransactionIcon'
import { descriptionMap, getTransactionType } from '../../../components/transactionUtils'
import { Transaction, TransactionType } from '../../../utils/interfaces'
import classes from './TransactionItem.module.css'

const TransactionItem = ({ transaction }: { transaction: Transaction }) => {
  const transactionType: TransactionType = getTransactionType(transaction)
  return (
    <Box className={classes.item}>
      <Group
        wrap='nowrap'
        pl='1rem'
        align='center'
        gap={2}
        h='2.875rem'
      >
        <TransactionIcon transactionType={transactionType} />
        <Box pl='0.5rem' style={{ flexBasis: '100%' }}>
          <Text inline size='xs' c='var(--mantine-color-dark-0)' fw='bold' lh={1.3} mb='0.2rem'>
            {descriptionMap(transactionType)}
          </Text>
          <Text inline size='sm' c='nebulous.0' fw='bold'>
            {transaction.counterparty.name}
          </Text>
        </Box>
        <Text inline ta='right' fw='bold' style={{ flexShrink: 0 }} pr='sm'>
          {currencyFormat.format(transaction.amount) + ' ' + transaction.currency}
        </Text>
      </Group >
    </Box >
  )
}

export default TransactionItem
