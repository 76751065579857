.inner {
  --modal-x-offset: 0;
  --modal-y-offset: 0;
  @media (max-width: calc($mantine-breakpoint-md - 1px)) {
    --modal-size: 100%;
  }
}

.content {
  --paper-radius: 0;
  --modal-content-height: 100%;
  @media (min-width: $mantine-breakpoint-md) {
    --modal-content-height: auto;
    --paper-radius: var(--mantine-radius-lg);
  }
}

.overlay {
  backdrop-filter: var(--overlay-filter);
}
