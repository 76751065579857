import { Button, Checkbox, Chip, Divider, Flex, Group, NumberInput, Paper, TextInput } from '@mantine/core'
import { DateInput } from '@mantine/dates'
import { useContext, useState } from 'react'
import { useLocation } from 'wouter'
import { IconCloseDeleteNo, IconSearch, IconStatementSaveExport } from '../../../../assets/icons'
import { CSSGrid } from '../../../../components/CSSGrid'
import ChatButton from '../../../../components/ChatButton/ChatButton'
import { SessionContext } from '../../../../contexts'
import { Account, Transaction, User } from '../../../../utils/interfaces'
import classes from './TimelineWrapper.module.css'
import Timeline from '../Timeline/Timeline'

const TimelineWrapper = ({ account, user }: { account: Account; user: User }) => {
  const { session } = useContext(SessionContext)

  const [, navigate] = useLocation()

  const allValues = ['all', 'spent', 'loaded', 'transfers']
  const [selected, _setSelected] = useState(['all', 'spent', 'loaded', 'transfers'])
  const [showDateFilter, setShowDateFilter] = useState(false)
  const [showAmountFilter, setShowAmountFilter] = useState(false)

  const applyAllLogic = (previousValue: string[], newValue: string[]) => {
    if (previousValue.includes('all') && !newValue.includes('all')) {
      return []
    }
    if (!previousValue.includes('all') && newValue.includes('all')) {
      return allValues
    }
    if (previousValue.includes('all') && newValue.includes('all')) {
      if (newValue.length !== allValues.length) {
        return newValue.filter((v) => v !== 'all')
      }
    }
    if (!newValue.includes('all')) {
      if (newValue.length === allValues.length - 1) {
        return ['all', ...newValue]
      }
    }

    return newValue
  }

  const setSelected = (valueFromChips: string[]) => {
    return _setSelected((prev) => applyAllLogic(prev, valueFromChips))
  }
  const originalTransactions =
    account !== undefined
      ? session.transactions.filter((t) => t.account_id === account.id).map((t) => ({ ...t, account: account }))
      : user.accounts.reduce(
          (prev, current) => [...prev, ...session.transactions.filter((t) => t.account_id === current.id).map((t) => ({ ...t, account: current }))],
          [] as Transaction[]
        )

  // TODO: showing mocked transactions if none is present
  const transactions = originalTransactions.length === 0 ? session.transactions.map((t) => ({ ...t, account: user.accounts[0] })) : originalTransactions

  return (
    <div>
      <Paper p='md' bg='dark.8' shadow='cardnoir' radius='md'>
        <Flex justify='space-between' align='center' ff='heading' fw={600} fz='lg'>
          <div>Statement Centre</div>
          <Button variant='subtle' size='xs' leftSection={<IconStatementSaveExport height='1.3em' />} color='success' pl='4px' pr='4px'>
            Save/Export
          </Button>
        </Flex>

        <Divider size='sm' color='success' my='xs' />

        {/* <Select
            data={[
              { value: 'all', label: 'Showing all accounts' },
              ...user.accounts.map((account) => {
                return { value: account.id, label: account.name }
              }),
              ]}
            value={account?.id || 'all'}
            onChange={(id) => {
              navigate('~/member/statement-centre/' + (id === 'all' ? '' : id), { replace: true })
              }}
              /> */}
        <CSSGrid gap='md'>
          <CSSGrid templateColumns={{ base: '1fr', md: '1fr auto' }} gap='md'>
            <TextInput label='Filter transactions' type='text' placeholder='Search by beneficiary, reference...' color='success' />

            <Group gap='md' pt={{ md: '1.4rem' }}>
              <Checkbox
                label='By date'
                variant='outline'
                color='success'
                styles={{ label: { paddingLeft: '0.5rem' } }}
                checked={showDateFilter}
                onChange={() => setShowDateFilter(!showDateFilter)}
              />
              <Checkbox
                label='By amount'
                variant='outline'
                color='success'
                styles={{ label: { paddingLeft: '0.5rem' } }}
                checked={showAmountFilter}
                onChange={() => setShowAmountFilter(!showAmountFilter)}
              />
            </Group>
          </CSSGrid>
          {showDateFilter && (
            <CSSGrid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap='md'>
              <DateInput label='From date' clearable placeholder='from date. eg. 25 Sep 2023' valueFormat='DD MMMM YYYY' />
              <DateInput label='To date' clearable placeholder='to date. eg. 25 Oct 2023' valueFormat='DD MMMM YYYY' />
            </CSSGrid>
          )}
          {showAmountFilter && (
            <CSSGrid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap='md'>
              <NumberInput label='From amount' placeholder='min amount. eg. 2000' hideControls />
              <NumberInput label='To amount' placeholder='max amount. eg. 10000' hideControls />
            </CSSGrid>
          )}

          <Flex gap='sm' justify={{ md: 'space-between' }} align={{ base: 'center' }} direction={{ base: 'column', md: 'row' }}>
            <Chip.Group multiple={true} value={selected} onChange={setSelected}>
              <Flex gap={{ base: 6, md: 'xs' }} justify='center'>
                <Chip value='all' radius='sm' size='sm' color='success.6' autoContrast>
                  All
                </Chip>
                <Chip value='spent' radius='sm' size='sm' color='success' autoContrast>
                  spent
                </Chip>
                <Chip value='loaded' radius='sm' size='sm' color='success' autoContrast>
                  loaded
                </Chip>
                <Chip value='transfers' radius='sm' size='sm' color='success' autoContrast>
                  transfers
                </Chip>
              </Flex>
            </Chip.Group>
            <Group gap='xs'>
              <Button size='xs' leftSection={<IconCloseDeleteNo height='0.9em' />} color='primary.5' variant='outline' autoContrast>
                Clear filters
              </Button>
              <Button size='xs' leftSection={<IconSearch height='1em' />} autoContrast>
                Filter transactions
              </Button>
            </Group>
          </Flex>
        </CSSGrid>
        <Divider size='sm' color='success' my='md' />
        <Timeline transactions={transactions} />
      </Paper>

      <ChatButton className={classes.chatButton} />
    </div>
  )
}
export default TimelineWrapper
