.root {
  display: grid;
  grid-template-rows: auto 1fr auto auto;
  min-height: calc(100vh - 120px);
}

.formColumn {
  width: 16rem;
  margin-top: 16;
  border-left: 2pt solid var(--mantin---mantine-primary-color-3);
}

@media (max-width: $mantine-breakpoint-md) {
  .hideOnDesktop {
      display: none
  }
}