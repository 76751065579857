import dayjs from 'dayjs'

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}

export interface Address {
  id: string
  street1: string
  street2: string
  city: string
  country_code: string
  postal_code: string
}

export interface Identifier {
  id: string
  external_id: string
  source: string //TODO: improve
  type: string //TODO: improve
  valid_from: string
  valid_to: string
}

export interface BankDetails {
  id: string
  owner_id: string
  type: string //TODO: improve
  name: string
  payment_rail: string //TODO: can this be more restrictive ?
  bic: string
  iban: string
  created_at: string
  updated_at: string
}

export interface Card {
  id: string
  display_name: string
  account_id: string
  currency: string //TODO: improve
  exp_month: '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09' | '10' | '11' | '12'
  exp_year: `${number}${number}${number}${number}`
  last4: `${number}${number}${number}${number}`
  region: string //TODO: improve
  status: string //TODO
  created_at: string
  updated_at: string
  brand: 'visa' | 'mastercard'
  type: 'virtual' | 'physical'
}

export interface Account {
  id: string
  individual_id: string
  name: string //TODO: improve
  currency: string //TODO: improve
  balance: number
  type: string //TODO: improve
  alias: string
  created_at: string
  updated_at: string
  bank_details: BankDetails
  cards: Card[]
}

export interface User {
  id: string
  first_name: string
  last_name: string
  date_of_birth: string
  email: string
  phone_number: string
  status: string //TODO: add better type safety for status. Status should not be string.
  nationality: string[] //TODO improve type safety
  country_of_birth: string //TODO improve type safety
  language: string //TODO: improve
  promo_code: string
  agreed_to_tos: boolean
  agreed_to_promotions: boolean
  card_use: string //TODO: improve
  card_funding: string //TODO: improve
  address: Address
  shipping_address: Address
  other_identifiers: Identifier[]
  accounts: Account[]
  lastRefresh: dayjs.Dayjs
}

export interface IBANAccountDetails {
  name: string
  account: {
    iban: string
    bic: string
    account_holder_name: string
    bank_name: string
  }
}

export interface AccountIdAccountDetails {
  name: string
  account_id: string
}

export enum TransactionType {
  INTERNAL_TRANSFER = 'INTERNAL_TRANSFER',
  INTERNAL_TRANSFER_OUT = 'INTERNAL_TRANSFER_OUT',
  INTERNAL_TRANSFER_IN = 'INTERNAL_TRANSFER_IN',
  ATM_WITHDRAWAL = 'ATM_WITHDRAWAL',
  CARD_PAYMENT = 'CARD_PAYMENT',
  BANK_TRANSFER = 'BANK_TRANSFER',
  BANK_TRANSFER_IN = 'BANK_TRANSFER_IN',
  BANK_TRANSFER_OUT = 'BANK_TRANSFER_OUT',
  card_payment = 'card-payment'
}

export interface Transaction {
  account_id: string
  amount: number
  counterparty: IBANAccountDetails | AccountIdAccountDetails
  created_at: string
  currency: string
  id: string
  reference: string
  request_id: string
  status: string
  type: TransactionType
}
export interface Beneficiary {
  id: string
  name: string
  alias: string
  payment_type: 'sepa' | 'swift' | 'cardnoir'
  entity_type: 'individual' | 'corporate'
  currency: string //TODO:  be more specific
  email: string
  status: 'active' | 'inactive' | 'pending'
  account_details: IBANAccountDetails | AccountIdAccountDetails
  country: string //TODO: more specific
  reference?: string
}

export const isAccountIdAccountDetails = (val: IBANAccountDetails | AccountIdAccountDetails) => {
  return 'account_id' in val
}

export const isIBANAccountDetails = (val: IBANAccountDetails | AccountIdAccountDetails) => {
  return 'account' in val
}

export interface Session {
  transactions: Transaction[]
  beneficiaries: Beneficiary[]
  onboardingUser: Partial<User>
}
