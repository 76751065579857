import { ActionIcon, Box, Flex, Title } from '@mantine/core'
import NavbarBackground from '../../assets/top_decco.svg'
import classes from './AppHeader.module.css'
import Container from './Container'

export interface AppHeaderProps {
  title?: string
  description?: string
  icon?: JSX.Element
  buttons?: JSX.Element[]
  className?: string
  blur?: boolean
  iconOnClick?: () => void
}

export const AppHeader = ({ title, description, icon, buttons, className = '', blur = false, ...props }: AppHeaderProps) => {
  return (
    <Box pos='relative' h={100} style={{ zIndex: 1 }} className={className}>
      <Box pos='fixed' left={0} right={0} h={100} style={{ filter: blur ? 'blur(5px)' : undefined }}>
        <Box pos='fixed' left={0} right={0} h={100}>
          <Container className={classes.container}>
            <Flex align='center' gap='xs'>
              {icon && props.iconOnClick !== undefined && (
                <ActionIcon color='primary.6' onClick={props.iconOnClick} variant='outline' size='md'>
                  {icon}
                </ActionIcon>
              )}
              {icon && props.iconOnClick === undefined && <div className={classes.nonClickableIcon}>{icon}</div>}
              <Box w='100%'>
                <Title display='inline-block' order={1} c='primary' fw={600} ta='left' size={'h3'} px='0.5rem' pt='0.1rem' lh='1.2' fz={{ md: 26 }}>
                  {title}
                </Title>
                <Box fz={{ base: 'xs', md: 'sm' }} c='dark.0' ta={{ base: 'center', md: 'left' }} pb='0.3rem' pl='0.5rem' lh='1.2'>
                  {description}
                </Box>
              </Box>
            </Flex>
            <Flex gap='xxs'>
              {buttons?.map((b, i) => (
                <div key={i}>{b}</div>
              ))}
            </Flex>
          </Container>
        </Box>
        <Box component={NavbarBackground} h={100} w='100%' />
        <Box
          pos='absolute'
          bottom={0}
          w='100%'
          h={5}
          style={{
            backgroundColor: 'var(--mantine-color-primary-6)',
            background: 'linear-gradient(225deg, var(--mantine-color-primary-6), var(--mantine-color-error-6))',
          }}
        />{' '}
      </Box>
    </Box>
  )
}

export default AppHeader
