import { Title, TitleProps } from '@mantine/core'
import React from 'react'

export const HeaderSecondary: React.FC<React.PropsWithChildren<TitleProps>> = ({ children, ...props }) => {
  return (
    <Title order={2} c='primary' fw={700} ta='left' size={'h3'} {...props}>
      {children}
    </Title>
  )
}
