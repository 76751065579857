.actionIcon {
  border-width: 2pt;
  &[data-variant='outline'] {
    --ai-size-xs: calc(1.875rem * var(--mantine-scale));
    --ai-size-sm: calc(2.25rem * var(--mantine-scale));
    --ai-size-md: calc(2.625rem * var(--mantine-scale));
    --ai-size-lg: calc(3.125rem * var(--mantine-scale));
    --ai-size-xl: calc(3.75rem * var(--mantine-scale));
  }
}

.button {
  &[data-variant='outline'] {
    border-width: 2pt;
  }
  &[data-variant='outline']&[data-disabled='true'] {
    border-color: var(--mantine-color-dark-4);
  }

  &[data-variant='cardnoir'],
  &[data-variant='cardnoir-white'] {
    border: none;
    padding: 0;

    &[data-with-left-section] {
      padding-right: calc(var(--button-padding-x, var(--mantine-padding-sm)) * 0.5);
    }

    &[data-with-right-section] {
      padding-left: calc(var(--button-padding-x, var(--mantine-padding-sm)) * 0.5);
    }
  }

  &[data-variant='contextual-card'] {
    padding-inline-start: 0 !important;
    height: auto !important;
    background-color: transparent !important;
  }

  &[data-variant='vertical'] {
    transition: box-shadow 0.1s ease 0s;
    background-color: var(--button-color, transparent);
    padding: 0.2rem;
    font-size: var(--mantine-font-size-sm);
    height: auto;
  }
}

.buttonInner {
  [data-variant='cardnoir'] &,
  [data-variant='cardnoir-white'] & {
    justify-content: flex-start;
  }

  [data-variant='contextual-card'] & {
    justify-content: flex-start !important;
  }

  [data-variant='vertical'] & {
    display: flex;
    flex-direction: column;
    gap: 0.1875rem;
    font-weight: 400;
  }
}

.buttonInner svg {
  [data-variant='contextual-card'] & {
    border-width: 2pt;
    border-style: solid;
    border-radius: 6px;
    padding: 0.3rem;
  }
}

.buttonLabel {
  [data-variant='cardnoir'] &,
  [data-variant='cardnoir-white'] & {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    line-height: 1.3;
    text-align: left;

    font-weight: 500;

    *:nth-child(2) {
      font-size: calc(0.85 * var(--button-fz, var(--mantine-font-size-sm)));
      color: var(--mantine-color-nebulous-4);
      font-weight: 400;
    }
  }

  [data-disabled] & {
    *:nth-child(2) {
      color: var(--mantine-color-red-5);
    }
  }

  [data-variant='contextual-card'] & {
    display: flex;
    flex-direction: column;
    height: auto !important;
    align-items: flex-start !important;
  }

  [data-variant='vertical'] & {
      font-weight: 600;
      font-size: var(--mantine-font-size-xs);
      padding-bottom: 1;
  }
}

.buttonLabel p {
  [data-variant='contextual-card'] & {
    margin: 0
  }
}

.label p:nth-child(2) {
  [data-variant='contextual-card'] & {
    color: var(--mantine-color-gray-1);
    line-height: 1;
  }
}

.buttonSection {
  [data-variant='cardnoir'] &,
  [data-variant='cardnoir-white'] & {
    display: flex;
    height: 100%;
    padding: 0.3rem;
    aspect-ratio: 1;
    align-items: center;
    justify-content: center;
    border: var(--button-bd, '1pt solid var(--mantine-color-primary-6)');
    border-width: 2pt;
    border-radius: var(--mantine-radius-default);
  }
  [data-variant='vertical'] & {
    margin-inline-end: 0;
  }
  [data-disabled] & {
    border-color: var(--mantine-color-dark-3);
  }
}

.inputWrapperError {
  margin-top: 0.125rem;
}

.input {
  color: var(--mantine-color-primary-6);

  &[data-error='true'] {
    color: var(--mantine-color-error);
  }
}
