import React from 'react'
import FundsCardIcon from '../assets/icons/Icon_Funds_Card.svg'
import FundsCashIcon from '../assets/icons/Icon_Funds_Cash.svg'
import FundsLoadIcon from '../assets/icons/Icon_Funds_Load.svg'
import { IconFundsSend } from '../assets/icons/'
import { IconFundsTransfer } from '../assets/icons'
import classes from './TransactionIcon.module.css'
import { TransactionType } from '../utils/interfaces'

const TransactionIcon: React.FC<{ transactionType: TransactionType }> = ({ transactionType }) => {

  switch (transactionType) {
    case TransactionType.INTERNAL_TRANSFER_OUT:
      return <IconFundsTransfer className={classes.icon} />
    case TransactionType.INTERNAL_TRANSFER_IN:
      return <IconFundsTransfer className={classes.icon} style={{ transform: 'rotate(-90deg)' }} />
    case TransactionType.ATM_WITHDRAWAL:
      return <FundsCashIcon className={classes.icon} />
    case TransactionType.CARD_PAYMENT:
      return <FundsCardIcon className={classes.icon} />
    case TransactionType.BANK_TRANSFER_IN:
      return <FundsLoadIcon className={classes.icon} style={{ transform: 'rotate(90deg)' }} />
    case TransactionType.BANK_TRANSFER_OUT:
      return <IconFundsSend className={classes.icon} style={{ transform: 'rotate(90deg)' }} />
  }
  return <></>
}

export default TransactionIcon
